import axios from "utils/axios";

export const createMessage = (message) =>
  new Promise((resolve, reject) => {
    axios
      .post("/conversation/create/message", message)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
/**
 * Inserts one or more message objects
 * @param {Array} messages : Array of message objects
 * @returns {Promise} Promise : message insert response
 */
export const insertMessage = (messages) =>
  new Promise((resolve, reject) => {
    axios
      .post("/conversation/create", messages)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const findMessage = (query) =>
  new Promise((resolve, reject) => {
    axios
      .post("/conversation/find", query)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const findInterviewList = (candidateId, jobId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/conversation/list/interview/candidate/${candidateId}/job/${jobId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const findTechnicalList = (candidateId, jobId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/conversation/list/technical/candidate/${candidateId}/job/${jobId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const deleteTechnicallist = (submissionId) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`/conversation/delete/submission/${submissionId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const deleteInterviewTextResponse = (Id) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`/conversation/delete/text/${Id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

// screening list inside conversation
export const getConversationScreeningList = (candidateId, jobId) =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `/conversation/datalist/screening/candidate/${candidateId}/job/${jobId}`,
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

// screenin delete inside conversation
export const deleteScreeningQuestion = (conversationId) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`/conversation/delete/${conversationId}`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateConversation = (conversationId, data) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/conversation/update/${conversationId}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getMeetList = (candidateId, jobId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/conversation/list/meet/candidate/${candidateId}/job/${jobId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getConversation = (candidateId, jobId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/conversation/get/${candidateId}/job/${jobId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getChatUserList = (userId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/conversation/user/list/${userId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getUserChat = (senderId, recipientId, page) =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `/conversation/chat/sender/${senderId}/recipient/${recipientId}?pageNumber=${page}`,
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getChatUserAssessments = (
  candidateId,
  userId,
  limit = 10,
  page = 0,
) =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `/conversation/list/candidate/assessments/${candidateId}/user/${userId}?page=${page}&limit=${limit}`,
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
