import React, { useState, useEffect, useRef } from "react";
import { JSX } from "@types/react";
import * as Yup from "yup";

import { Link, useHistory } from "react-router-dom";

import { alpha, styled } from "@mui/material/styles";
import {
  CircularProgress,
  IconButton,
  InputBase,
  InputLabel,
  FormControl,
  Checkbox,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
} from "@mui/material";

import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import ReCAPTCHA from "react-google-recaptcha";

import CountrySelect from "components/CountrySelector";

import GithubAuth from "components/SocialAuth/GithubAuth";
import GoogleAuth from "components/SocialAuth/GoogleAuth";

import { createAuthUser } from "api/auth";

import SnackBarComponent from "components/Snackbar";

import "./style.css";
import { findByEmail } from "api/users";
import PasswordStrengthMeter from "components/PasswordStrengthMeter";
import {
  GOOGLE_CAPTCHA_KEY,
  SERVER_URL,
  isTesting,
} from "constants/production";
import NewsletterSubscription from "components/NewsletterSubscription";
import PrivacyPolicyModal from "components/PrivacyPolicyModal";
import TermsConditionModal from "components/TermsConditionModal";

const passwordIconButton = {
  color: "#bdbdbd",
  background: "rgba(255, 255, 255, 0.15)",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.45)",
    color: "#007bff",
  },
};

const formControlStyle = {
  display: "flex",
  flex: 1,
  "& label.MuiFormLabel-root": {
    color: "#242424 !important",
  },
};

const label = { inputProps: { "aria-label": "Checkbox" } };

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: "#242424",
  },
  "& .MuiInputBase-input": {
    // width: "100%",
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#fafafa",
    border: "1px solid",
    borderColor: "rgba(0, 123, 255, 0.2)",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "'Poppins'",
      "'Inter'",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha("#007bff", 0.25)} 0 0 0 0.2rem`,
      borderColor: "#007bff",
    },
  },
  "&.MuiInputBase-root.error": {
    "& .MuiInputBase-input": {
      boxShadow: `${alpha("#ff1744", 0.25)} 0 0 0 0.2rem !important`,
      borderColor: "#ff1744 !important",
    },
  },
  "&.phone-input": {
    left: 4,
    "& .MuiInputAdornment-root": {
      display: "none",
    },
  },
  "&.phone-input.active": {
    // // left: "-70px",
    width: "385px",
    marginLeft: "-60px",
    "& input": {
      paddingLeft: "65px",
    },
    "& .MuiInputAdornment-root": {
      display: "flex",
      position: "relative",
      width: "75px",
      left: "70px",
      zIndex: "1000",
    },
  },

  "&.email-input": {
    "& .MuiInputAdornment-root": {
      display: "flex",
      position: "relative",
      marginRight: "-20px",
      right: "35px",
      zIndex: "1000",
      width: 10,
      transition: "all 0.2s ease-in",
    },
  },

  "&.password-input": {
    "& .MuiInputAdornment-root": {
      display: "flex",
      position: "relative",
      marginRight: "-48px",
      right: "55px",
      zIndex: "1000",
      transition: "all 0.2s ease-in",
    },
  },
}));

const accordionStyle = {
  boxShadow: "none !important",
  "&::before": {
    backgroundColor: "#fff",
  },
  "& .MuiPaper-root": {
    boxShadow: "none !important",
  },
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .matches(/^[a-zA-Z\s]+$/, "Name can only contain letters and spaces"),

  email: Yup.string()
    .email("Invalid email address.")
    .required("Email is required."),

  phone: Yup.string()
    .required("Phone number is required.")
    .matches(/^\d{10}$/, "Phone number must be exactly 10 digits."),

  username: Yup.string()
    .required("Username is required.")
    .min(4, "Username must be at least 4 characters.")
    .max(20, "Username must be at most 20 characters.")
    .matches(
      /^[a-zA-Z0-9_-]+$/,
      "Username can only contain letters, numbers, underscores, and hyphens."
    ),
});

const validationPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required.")
    .min(4, "Password must be at least 4 characters."),

  confirmPassword: Yup.string()
    .required("Confirm Password is required.")
    .oneOf([Yup.ref("password"), null], "Passwords must match."),
});

const validationCompanySchema = Yup.object().shape({
  companyName: Yup.string().required("Company name is required"),

  companyLocation: Yup.string().required("Company location is required"),

  companyWebsite: Yup.string().required("website is required"),

  companyMail: Yup.string()
    .email("Invalid email address.")
    .required("Company email is required."),

  companyContact: Yup.string()
    .required("Company contact number is required.")
    .matches(/^\d{10}$/, "Phone number must be exactly 10 digits."),
});

type Props = {
  onSubmit: any;
  loginUrl: string;
  isShadow: boolean;
};

type CountryType = {
  code: string;
  label: string;
  phone: string;
};

type CompanyType = {
  title: string;
  value?: string;
  mail?: string;
  location?: string;
  website?: string;
  contact?: string;
};

type UserTypeData = {
  name: string;
  age: number;
};

const CreateAccountForm: React.FC = ({
  onSubmit,
  loginUrl,
  isShadow = true,
}: Props): JSX.IntrinsicElements => {
  const history = useHistory();
  const recaptchaRef = useRef();

  const [name, setName] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [userType, setUserType] = useState<string>("candidate");
  const [company, setCompany] = useState<CompanyType>();
  const [country, setCountry] = useState<CountryType>();
  const [isNewCompany, setIsNewCompany] = useState<boolean>(false);

  const [companyName, setCompanyName] = useState<string>("");
  const [companyMail, setCompanyMail] = useState<string>("");
  const [companyLocation, setCompanyLocation] = useState<string>("");
  const [companyWebsite, setCompanyWebsite] = useState<string>("");
  const [companyContact, setCompanyContact] = useState<string>("");

  // snackbar
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarStatus, setSnackbarStatus] = useState<number>(0);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const [isEmailExists, setIsEmailExists] = useState<boolean>(true);
  const [emailCheckStatus, setEmailCheckStatus] = useState<boolean>(false);

  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);

  const [errors, setErrors] = useState({});

  const [showPassword, setShowPassword] = useState(false);

  const [isAgreed, setIsAgreed] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState("");

  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  const [isPrivacyModal, setIsPrivacyModal] = useState(false);
  const [isTermsModal, setIsTermsModal] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // validate yup schema
  const validateItem = (key: string, value: string, schema = "user") =>
    new Promise((resolve, reject) => {
      if (schema === "company") {
        validationCompanySchema
          .validateAt(key, { [key]: value })
          .then(() => resolve(""))
          .catch((error) => resolve(error.message));
      } else {
        validationSchema
          .validateAt(key, { [key]: value })
          .then(() => resolve(""))
          .catch((error) => resolve(error.message));
      }
    });

  const validatePassword = (values) => {
    validationPasswordSchema
      .validate(values, { abortEarly: false })
      .then((validValues) => {
        // Validation succeeded, use the validValues as needed
        setErrors((prev) => ({ ...prev, password: "" }));
      })
      .catch((errors) => {
        // Validation failed, handle errors
        setErrors((prev) => ({ ...prev, password: errors.errors[0] }));
      });
  };

  const validateFormData = async (data, schema) => {
    try {
      const validValues = await schema.validate(data, { abortEarly: false });
      // Validation succeeded, use the validValues as needed
      return true;
    } catch (errors) {
      // Validation failed, handle errors
      if (errors.inner && errors.inner.length > 0) {
        const errorMessages = {};
        errors.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        setErrors((prev) => ({ ...prev, ...errorMessages }));
      }
      return false;
    }
  };

  const validateForm = async () => {
    const companyData = {
      companyName,
      companyLocation,
      companyMail,
      companyContact,
      companyWebsite,
    };

    const userData = {
      name,
      email,
      username: userName,
      phone,
    };

    const validate = [
      validateFormData(userData, validationSchema),
      validateFormData({ password, confirmPassword }, validationPasswordSchema),
    ];

    if (userType === "user") {
      validate.push(validateFormData(companyData, validationCompanySchema));
    }
    // Use Promise.all to handle multiple validations simultaneously
    return Promise.all(validate)
      .then((results) => {
        // If all validations are successful, results will be an array of true values
        const allValid = results.every((isValid) => isValid);

        if (allValid) {
          // All validations succeeded, do something (e.g., submit form)
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        console.warn("form validation failed, Error: ", error);
        return false;
      });
  };

  const updateSnackbar = (
    open: boolean,
    status: number,
    message: string,
    autoHide = true
  ) => {
    setSnackbarOpen(open);
    setSnackbarStatus(status);
    setSnackbarMessage(message);

    if (autoHide) {
      setTimeout(() => {
        setSnackbarOpen(false);
        setSnackbarStatus(0);
        setSnackbarMessage("");
      }, 5000);
    }
  };

  const handleNameChange = (value: string) => {
    setName(value);
    validateItem("name", value).then((error) =>
      setErrors((prev) => ({ ...prev, name: error }))
    );
  };

  const handleUserNameChange = (value: string) => {
    setUserName(value);
    validateItem("username", value).then((error) =>
      setErrors((prev) => ({ ...prev, username: error }))
    );
  };

  const handleEmailChange = async (value: string) => {
    setEmail(value);
    validateItem("email", value).then((error) =>
      setErrors((prev) => ({ ...prev, email: error }))
    );
  };

  const handlePhoneNumberChange = (value: string) => {
    setPhone(value);
    validateItem("phone", value).then((error) =>
      setErrors((prev) => ({ ...prev, phone: error }))
    );
  };

  const handleUserTypeChange = (type: string) => {
    setUserType(type);
  };

  const handleCompanySelection = (selectedCompany: CompanyType) => {
    setCompany(selectedCompany);
    setIsNewCompany(!selectedCompany?.value);
  };

  const handleCountryChange = (e: Event, newValue: CountryType) => {
    setCountry(newValue);
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    validatePassword({ password: value, confirmPassword });
  };

  const handleConfirmPasswordChange = (value: string) => {
    setConfirmPassword(value);
    validatePassword({ password, confirmPassword: value });
  };

  const handleCompanyNameChange = (value: string) => {
    setCompanyName(value);
    validateItem("companyName", value, "company").then((error) =>
      setErrors((prev) => ({ ...prev, companyName: error }))
    );
  };
  const handleCompanyMailChange = (value: string) => {
    setCompanyMail(value);
    validateItem("companyMail", value, "company").then((error) =>
      setErrors((prev) => ({ ...prev, companyMail: error }))
    );
  };
  const handleCompanyLocationChange = (value: string) => {
    setCompanyLocation(value);
    validateItem("companyLocation", value, "company").then((error) =>
      setErrors((prev) => ({ ...prev, companyLocation: error }))
    );
  };
  const handleCompanyWebsiteChange = (value: string) => {
    setCompanyWebsite(value);
    validateItem("companyWebsite", value, "company").then((error) =>
      setErrors((prev) => ({ ...prev, companyWebsite: error }))
    );
  };
  const handleCompanyContactChange = (value: string) => {
    setCompanyContact(value);
    validateItem("companyContact", value, "company").then((error) =>
      setErrors((prev) => ({ ...prev, companyContact: error }))
    );
  };

  useEffect(() => {
    let debounce: number | undefined;
    if (email && !errors?.email) {
      debounce = setTimeout(() => {
        checkEmailExists(email);
      }, 1000);
    }

    return () => {
      debounce && clearTimeout(debounce);
    };
  }, [email, errors?.email]);

  const checkEmailExists = async (email: string) => {
    setEmailCheckStatus(true);
    findByEmail(email)
      .then((response) => {
        setEmailCheckStatus(false);
        const emailExist = response?.data?.email === email;
        if (emailExist) {
          setIsEmailExists(response?.emailExists);
          setErrors((prev) => ({ ...prev, email: "Email already exist" }));
        } else {
          setIsEmailExists(false);
        }
      })
      .catch((error) => {
        setEmailCheckStatus(false);
        console.error("error", error);
      });
  };

  const handleEmailAdornment = () => {
    if (emailCheckStatus) {
      return <CircularProgress size={14} sx={{ color: "#007bff" }} />;
    }

    if (!emailCheckStatus && !isEmailExists && !errors?.email && email) {
      // email not exist
      return <CheckCircleRoundedIcon sx={{ color: "#00e676", fontSize: 12 }} />;
    }

    if (!emailCheckStatus && isEmailExists && email) {
      // email exist
      return <CancelRoundedIcon sx={{ color: "#ff1744", fontSize: 12 }} />;
    }

    return <></>;
  };

  const createAccount = async () => {
    const companyData = {};

    updateSnackbar(true, 0, "Creating account, please wait...", false);

    // check if company not exist
    if (userType === "user") {
      companyData.title = companyName;
      companyData.location = companyLocation;
      companyData.mail = companyMail;
      companyData.contact = companyContact;
      companyData.website = companyWebsite;
    }

    const userData = {
      name,
      email,
      username: userName,
      userType,
      country,
      phone,
      password,
      company: { ...company, ...companyData },
      newsletter_subscription: isSubscribed,
    };

    const user = await createAuthUser(userData);
    onSubmit(user);
    setIsSubmitting(false);

    if (user?.response?.userType === "candidate") {
      updateSnackbar(
        true,
        1,
        "Account created,Verification mail has been send to your mail...",
        true
      );
    } else {
      updateSnackbar(
        true,
        1,
        "Verification mail has been send to your mail...",
        true
      );
    }
  };

  const handleCreateAccountClick = async (e: Event) => {
    try {
      const result = await validateForm();
      if (!result) return false;

      const emailExists = await checkEmailExists(email);
      if (emailExists) {
        updateSnackbar(true, 1, "Email already exists...", true);
        return false;
      }

      setIsSubmitting(true);

      // skip re-captcha, if testing
      if (isTesting) {
        return await createAccount();
      }

      const captchaToken = await recaptchaRef.current.executeAsync();

      const response = await fetch(`${SERVER_URL}/api/recaptcha/verify-recaptcha`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ recaptchaResponse: captchaToken }),
      });

      const { success } = await response.json();

      if (success) {
        // reCAPTCHA verification succeeded, continue with form submission
        // ...
        await createAccount();
      } else {
        setIsSubmitting(false);
        // reCAPTCHA verification failed
        return "reCAPTCHA verification failed";
      }
    } catch (error) {
      setIsSubmitting(false);
      // clearValuesofUser();
      updateSnackbar(true, 3, "Failed to create user", true);
      console.error("Failed to create user, Error: ", error);
    }
  };

  const handleGithubLogin = (response) => {
  };

  const handleGithubLoginError = (error) => {
  };

  const handleGoogleLogin = (response) => {
  };

  const handleGoogleLoginError = (error) => {
    console.error("Google login failed: ", error);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaResponse(token);
  };

  const handleSubscriptionChange = (subscribed: boolean) => {
    setIsSubscribed(subscribed);
  };

  return (
    <div className="create-account-form-container create-account-form">
      <SnackBarComponent
        open={snackbarOpen}
        status={snackbarStatus}
        message={snackbarMessage}
      />
      <div className={isShadow ? "form-wrapper is_shadow" : "form-wrapper"}>
        <h1 className="form-title" style={{ marginTop: 10 }}>
          Sign up to Bellohire
        </h1>
        <div className="form-container">
          <div className="social-auth-wrapper" style={{ display: "none" }}>
            {/* <div className="social-auth-title">Sign up with</div> */}
            <div className="form-row">
              <GithubAuth
                mode="light"
                onSignIn={handleGithubLogin}
                onError={handleGithubLoginError}
              >
                Github
              </GithubAuth>

              <GoogleAuth
                mode="light"
                onSignIn={handleGoogleLogin}
                onError={handleGoogleLoginError}
              >
                Google
              </GoogleAuth>
            </div>
          </div>

          <div className="divider-wrapper" style={{ display: "none" }}>
            <Divider orientation="horizontal" sx={{ width: "150px" }} />
            <p>or</p>
            <Divider orientation="horizontal" sx={{ width: "150px" }} />
          </div>

          {/* radio button */}
          <div className="radio-inputs">
            <label>
              <input
                className="radio-input"
                type="radio"
                name="userType"
                value="candidate"
                checked={userType === "candidate" ? true : false}
                onChange={(e) => handleUserTypeChange(e.target.value)}
              />
              <span className="radio-tile">
                <span className="radio-icon">
                  <svg
                    data-testid="select-candidate"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    viewBox="0 0 125.023 125.023"
                  >
                    <g>
                      <g>
                        <path
                          d="M65.176,57.92c16,0,28.952-12.972,28.952-28.962C94.128,12.963,81.176,0,65.176,0C49.183,0,36.218,12.964,36.218,28.958
			C36.218,44.949,49.183,57.92,65.176,57.92z"
                        />
                        <path
                          d="M72.632,59.087l-7.211,7.264l-6.993-7.34c-0.024,0.006-0.05,0.006-0.066,0.012c-1.167,0.28-6.12,1.856-12.546,6.465
			c-0.057,0.04-1.362,0.945-1.973,1.328c-1.213,0.766-3.024,1.875-5.215,2.922c-2.178,1.064-4.758,2.027-7.106,2.531
			c-1.159,0.23-2.206,0.293-3.047,0.266c-0.869-0.016-1.369-0.204-1.899-0.436c-0.285-0.066-0.496-0.334-0.808-0.482
			c-0.244-0.324-0.597-0.479-0.862-0.939c-0.142-0.203-0.305-0.373-0.457-0.593l-0.411-0.761c-0.318-0.452-0.519-1.126-0.776-1.706
			c-0.281-0.558-0.426-1.292-0.635-1.935c-0.218-0.637-0.364-1.336-0.491-2.037c-0.322-1.348-0.473-2.755-0.63-4.047
			c-0.193-1.274-0.181-2.553-0.276-3.632c-0.003-0.031-0.001-0.058-0.003-0.089c0.613-0.878,1.446-1.67,2.459-2.405
			c1.012-0.727,1.808-1.937,2.336-3.094c2.054-4.563,2.947-7.176,4.421-11.962c0.622-2.016-3.096-4.247-5.522,1.459
			c-1.026,2.067-0.578,2.279-1.621,4.338l-0.373,0.701c0,0-0.215-1.988-0.243-2.589c-0.323-6.89-0.618-10.586-0.949-17.476
			c-0.09-1.911-0.886-2.762-2.361-2.66c-1.404,0.101-2.021,0.966-1.946,2.823c0.151,3.761,0.331,4.323,0.483,8.081
			c0.071,1.417-0.851,1.148-0.845-0.006c-0.244-5.126-0.477-6.258-0.683-11.385c-0.058-1.392-0.637-2.305-2.064-2.458
			c-1.379-0.146-2.321,0.999-2.251,2.742c0.205,4.955,0.45,5.915,0.654,10.871c0.072,1.466-0.83,1.235-0.833,0.133
			c-0.183-3.928-0.299-4.667-0.583-8.588c-0.055-0.79-0.535-1.828-1.156-2.242c-1.515-1.009-3.171,0.277-3.101,2.369
			c0.146,4.387,0.383,5.577,0.564,9.96c0.109,1.125-0.772,1.427-0.82,0.117c-0.136-2.791-0.241-2.389-0.394-5.177
			c-0.07-1.271-0.794-1.997-2.072-2.046c-1.291-0.047-2.002,0.704-2.212,1.918c-0.09,0.497-0.042,1.022-0.019,1.531
			c0.294,6.608,0.471,10.029,0.959,16.622c0.174,2.309,0.451,3.921,0.829,5.785c0.378,1.864,1.418,2.743,1.667,3.666
			c-0.058,1.068-0.128,2.19-0.086,3.477c0.023,1.71,0.033,3.558,0.27,5.615c0.082,1.012,0.19,2.062,0.416,3.182
			c0.215,1.114,0.345,2.219,0.72,3.428c0.348,1.197,0.616,2.388,1.18,3.666c0.259,0.63,0.52,1.264,0.783,1.9
			c0.312,0.643,0.69,1.293,1.051,1.939c0.659,1.296,1.715,2.576,2.692,3.828c1.162,1.193,2.332,2.404,3.784,3.361
			c2.788,1.992,6.115,3.328,9.163,3.834c3.063,0.549,5.932,0.553,8.498,0.308c0.689-0.077,1.532-0.168,2.192-0.269l0.019,33.848
			h59.882v-12.961c1.321,3.738,2.566,8.053,3.745,12.961h23.102C116.131,93.336,98.253,67.534,72.632,59.087z M65.487,123.662
			h-0.128l-6.987-9.557l6.987-46.678h0.128l6.992,46.678L65.487,123.662z"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
                <span className="radio-label">Candidate</span>
              </span>
            </label>
            <label>
              <input
                className="radio-input"
                type="radio"
                name="userType"
                value="user"
                onChange={(e) => handleUserTypeChange(e.target.value)}
              />
              <span className="radio-tile">
                <span className="radio-icon">
                  <svg
                    version="1.0"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    viewBox="0 0 64 64"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M60,16H48V4c0-2.211-1.789-4-4-4H20c-2.211,0-4,1.789-4,4v12H4
	c-2.211,0-4,1.789-4,4v40c0,2.211,1.789,4,4,4h56c2.211,0,4-1.789,4-4V20C64,17.789,62.211,16,60,16z M24,8h16v8H24V8z"
                    />
                  </svg>
                </span>
                <span className="radio-label">Recruiter</span>
              </span>
            </label>
          </div>
          <div className="form-row">
            <div className="form-element-wrapper">
              <FormControl variant="standard" sx={formControlStyle}>
                <BootstrapInput
                data-testid="candidate-name"
                  id="name-input"
                  className={`bootstrap-input ${!!errors?.name && "error"}`}
                  value={name}
                  placeholder="Name"
                  onChange={(e) => handleNameChange(e.target.value)}
                  error={!!errors?.name}
                />
              </FormControl>
              <div className="error-text" data-testid="name-error">
                {errors?.name}
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="form-element-wrapper">
              <FormControl variant="standard" sx={formControlStyle}>
                <BootstrapInput
                  data-testid="candidate-user-name"
                  id="username-input"
                  className={`bootstrap-input ${!!errors?.username && "error"}`}
                  value={userName}
                  placeholder="Username"
                  onChange={(e) => handleUserNameChange(e.target.value)}
                />
              </FormControl>
              <div className="error-text" data-testid="username-error">
                {errors?.username}
              </div>
            </div>
          </div>

          <div className="form-row" style={{ alignItems: "center" }}>
            <div className="form-element-wrapper">
              <FormControl variant="standard" sx={formControlStyle}>
                <BootstrapInput
                  data-testid="email-input"
                  id="name-input"
                  className={`bootstrap-input email-input ${
                    !!errors?.email && "error"
                  }`}
                  value={email}
                  placeholder="Email"
                  onChange={(e) => handleEmailChange(e.target.value)}
                  type="email"
                  endAdornment={
                    <InputAdornment position="end">
                      {handleEmailAdornment()}
                    </InputAdornment>
                  }
                />
              </FormControl>
              <div className="error-text" data-testid="email-error">
                {errors?.email}
              </div>
            </div>
          </div>

          <div
            className="form-row"
            style={{
              marginTop: "10px",
              marginLeft: "16px",
              flex: 1,
              width: "93%",
            }}
          >
            <CountrySelect
              data-testid="country-select"
              onChange={(e, newValue) => handleCountryChange(e, newValue)}
            />
            <div className="error-text">{errors?.country}</div>
          </div>

          <div className="phone-number-wrapper">
            <div className="form-element-wrapper">
              <FormControl variant="standard" sx={formControlStyle}>
                <BootstrapInput
                  data-testid="phone-input"
                  id="phone-input"
                  className={`bootstrap-input  phone-input ${
                    !!errors?.phone && "error"
                  } ${country?.phone ? "active" : ""}`}
                  placeholder="Phone Number"
                  value={phone}
                  onChange={(e) => handlePhoneNumberChange(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      {country?.phone && (
                        <div className="phone-code">+{country?.phone}</div>
                      )}
                    </InputAdornment>
                  }
                />
              </FormControl>
              <div className="error-text" data-testid="phone-error">
                {errors?.phone}
              </div>
            </div>
          </div>

          {userType === "user" && (
            <Accordion
              sx={accordionStyle}
              expanded={isAccordionOpen}
              disableGutters={true}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#bdbdbd" }} />}
                aria-controls="company-details-content"
                id="company-details-header"
                onClick={() => setIsAccordionOpen(!isAccordionOpen)}
              >
                <div className="accordion-header">Add company details *</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="company-details-wrapper">
                  <div className="form-element-wrapper">
                    <FormControl variant="standard" sx={formControlStyle}>
                      <BootstrapInput
                        id="company-name-input"
                        className={`bootstrap-input ${
                          !!errors?.companyName && "error"
                        }`}
                        value={companyName}
                        placeholder="Your company name"
                        onChange={(e) =>
                          handleCompanyNameChange(e.target.value)
                        }
                        type="email"
                      />
                    </FormControl>
                    <div className="error-text">{errors?.companyName}</div>
                  </div>
                </div>

                <div className="company-details-wrapper">
                  <div className="form-element-wrapper">
                    <FormControl variant="standard" sx={formControlStyle}>
                      <BootstrapInput
                        id="company-email-input"
                        className={`bootstrap-input ${
                          !!errors?.companyMail && "error"
                        }`}
                        value={companyMail}
                        placeholder="Official e-mail address"
                        onChange={(e) =>
                          handleCompanyMailChange(e.target.value)
                        }
                        type="email"
                      />
                    </FormControl>
                    <div className="error-text">{errors?.companyMail}</div>
                  </div>

                  <div className="form-element-wrapper">
                    <FormControl variant="standard" sx={formControlStyle}>
                      <BootstrapInput
                        id="company-contact-input"
                        className="bootstrap-input"
                        value={companyContact}
                        placeholder="Company Contact Number"
                        onChange={(e) =>
                          handleCompanyContactChange(e.target.value)
                        }
                      />
                    </FormControl>
                    <div className="error-text">{errors?.companyContact}</div>
                  </div>

                  <div className="form-element-wrapper">
                    <FormControl variant="standard" sx={formControlStyle}>
                      <BootstrapInput
                        id="company-location-input"
                        className="bootstrap-input"
                        value={companyLocation}
                        placeholder="Company Location"
                        onChange={(e) =>
                          handleCompanyLocationChange(e.target.value)
                        }
                      />
                    </FormControl>
                    <div className="error-text">{errors?.companyLocation}</div>
                  </div>

                  <div className="form-element-wrapper">
                    <FormControl variant="standard" sx={formControlStyle}>
                      <BootstrapInput
                        id="company-contact-input"
                        className="bootstrap-input"
                        value={companyWebsite}
                        placeholder="Website"
                        onChange={(e) =>
                          handleCompanyWebsiteChange(e.target.value)
                        }
                        type="email"
                      />
                    </FormControl>
                    <div className="error-text">{errors?.companyWebsite}</div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          )}

          <div className="form-row">
            <div className="form-element-wrapper">
              <FormControl variant="standard" sx={formControlStyle}>
                <BootstrapInput
                  data-testid="password-input"
                  id="name-input"
                  className={`bootstrap-input password-input ${
                    !!errors?.password && "error"
                  }`}
                  type={showPassword ? "text" : "password"}
                  value={password}
                  placeholder="Password"
                  onChange={(e) => handlePasswordChange(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        sx={passwordIconButton}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
          </div>

          <PasswordStrengthMeter password={password} />

          <div className="form-row">
            <div className="form-element-wrapper">
              <FormControl variant="standard" sx={formControlStyle}>
                <BootstrapInput
                  data-testid="C_password-input"
                  id="name-input"
                  className={`bootstrap-input password-input ${
                    !!errors?.password && "error"
                  }`}
                  value={confirmPassword}
                  onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                  type={showPassword ? "text" : "password"}
                  placeholder="Confirm password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        sx={passwordIconButton}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
          </div>
          <div className="error-text" data-testid="password-error">
            {errors?.password}
          </div>

          <div className="form-row" style={{ alignItems: "center" }}>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={GOOGLE_CAPTCHA_KEY}
              onChange={handleRecaptchaChange}
            />
          </div>

          <div className="form-row terms-text-wrapper">
            <NewsletterSubscription
              onSubscriptionChange={handleSubscriptionChange}
            />
          </div>

          <div className="form-row terms-text-wrapper">
            <Checkbox
              data-testid="terms-checkbox"
              {...label}
              sx={{
                color: "#007bff",
                "&.Mui-checked": {
                  color: "#007bff",
                },
              }}
              value={isAgreed}
              checked={isAgreed}
              onChange={() => setIsAgreed(!isAgreed)}
            />
            <span>
              I agree with bellohire{" "}
              <span
                style={{ color: "#007bff", cursor: "pointer" }}
                onClick={() => setIsTermsModal(true)}
              >
                Terms of Service
              </span>
              ,{" "}
              <span
                style={{ color: "#007bff", cursor: "pointer" }}
                onClick={() => setIsPrivacyModal(true)}
              >
                Privacy Policy
              </span>
              , and our default notification settings.
            </span>
          </div>

          <div className="form-row">
            <button
              data-testid="create-account-button"
              className={
                isAgreed
                  ? "create-account-button"
                  : "create-account-button disabled"
              }
              onClick={() =>
                isAgreed && !isSubmitting && handleCreateAccountClick()
              }
            >
              {isSubmitting ? (
                <>
                  <CircularProgress
                    size={14}
                    style={{ color: "#fff", marginRight: 5 }}
                  />
                  Please wait...
                </>
              ) : (
                "Create account"
              )}
            </button>
          </div>
        </div>

        <div className="sign-in-container">
          Already have an account? &nbsp; <Link to={loginUrl}>Sign In</Link>
        </div>
      </div>

      <PrivacyPolicyModal
        open={isPrivacyModal}
        handleClose={() => setIsPrivacyModal(!isPrivacyModal)}
      />

      <TermsConditionModal
        open={isTermsModal}
        handleClose={() => setIsTermsModal(!isTermsModal)}
      />
    </div>
  );
};

export default CreateAccountForm;
