import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import produce from "immer";

interface User {
  id?: string;
  token?: string;
  fcmToken?: string;
  signupData?: any[];
  authStatus?: boolean;
  permissions?: any;
  deptPermissions?: any;
  sceneSetupPermission?: any;
  aiAssistantPermission?: any;
  allowRefferedCandidates?: any;
  departmentCreatePermission?: any;
  loggedUser?: any;
  company?: any;
  flowStep?: any;
  calendlyData?: any;
  scoreActionData?: any;
  sessionStatus?: boolean;
  addBot?: any;
}

interface UserState {
  user: User;
}

const initialState: UserState = {
  user: {
    id: "",
    token: "",
    fcmToken: "",
    signupData: [],
    authStatus: false,
    permissions: {},
    deptPermissions: {},
    sceneSetupPermission: {},
    aiAssistantPermission: {},
    allowRefferedCandidates: {},
    departmentCreatePermission: {},
    loggedUser: {},
    flowStep: {
      // status: false,
      // stage: 0,
      // userType: "",
      // userId: "",
      // departmentId: "",
      // departmentName: "",
      // jobId: "",
      // jobName: "",
    },
    calendlyData: {},
    scoreActionData: {},
    addBot: {},
    sessionStatus: false,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      console.log("state", state.user);
    },
    addCompany: (state, action: PayloadAction<{ signupData: any[] }>) => {
      state.user.signupData = action.payload.signupData;
    },
    updateAuthStatus: (state, action: PayloadAction<boolean>) => {
      state.user.authStatus = action.payload;
    },
    updateFirstLoginStatus: (state, action: PayloadAction<boolean>) => {
      state.user.isFirstLogin = action.payload;
    },
    updateSpaceSetupStatus: (state, action: PayloadAction<boolean>) => {
      state.user.isSpaceSetupPageVisited = action.payload;
    },
    userPermission: (state, action: PayloadAction<any>) => {
      state.user.permissions = action.payload;
    },
    departmentPermission: (state, action: PayloadAction<any>) => {
      state.user.deptPermissions = action.payload;
    },
    sceneSetupPermission: (state, action: PayloadAction<any>) => {
      state.user.sceneSetupPermission = action.payload;
    },
    aiAssistantPermission: (state, action: PayloadAction<any>) => {
      state.user.aiAssistantPermission = action.payload;
    },
    allowRefferedCandidates: (state, action: PayloadAction<any>) => {
      state.user.allowRefferedCandidates = action.payload;
    },

    departmentCreatePermission: (state, action: PayloadAction<any>) => {
      state.user.departmentCreatePermission = action.payload;
    },
    loggedUser: (state, action: PayloadAction<any>) => {
      state.user.loggedUser = action.payload;
    },
    flowStep: (state, action: PayloadAction<any>) => {
      state.user.flowStep = action.payload;
    },
    addCalendlyData: (state, action: PayloadAction<any>) => {
      state.user.calendlyData = action.payload;
    },
    addRequestScoreActionData: (state, action: PayloadAction<any>) => {
      state.user.scoreActionData = action.payload;
    },
    updateSessionStatus: (state, action: PayloadAction<boolean>) => {
      state.user.sessionStatus = action.payload;
    },
    addBot: (state, action: PayloadAction<any>) => {
      state.user.addBot = action.payload;
    },

    updateProfilePhoto: (state, action: PayloadAction<string>) => {
      state.user.loggedUser.profile_photo = action.payload;
    },
    updateAuthToken: (state, action: PayloadAction<string>) => {
      state.user.token = action.payload;
    },
    updateFcmToken: (state, action: PayloadAction<string>) => {
      state.user.fcmToken = action.payload;
    },
  },
});

export const {
  addUser,
  addCompany,
  updateAuthStatus,
  updateFirstLoginStatus,
  updateSpaceSetupStatus,
  userPermission,
  departmentPermission,
  sceneSetupPermission,
  aiAssistantPermission,
  allowRefferedCandidates,
  departmentCreatePermission,
  loggedUser,
  flowStep,
  addCalendlyData,
  addRequestScoreActionData,
  updateSessionStatus,
  addBot,
  updateProfilePhoto,
  updateAuthToken,
  updateFcmToken,
} = userSlice.actions;

export default userSlice.reducer;
