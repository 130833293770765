import React, { useEffect, useRef, useState } from "react";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { useParams, useLocation } from "react-router-dom";
import {
  cs_addAvatarSpeech,
  cs_resetAvatar,
  cs_updateAvatar,
} from "redux/space/reducers/avatarSpeechSlice";

import {
  TextField,
  Typography,
  InputAdornment,
  Tooltip,
  CircularProgress,
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Alert,
} from "@mui/material";

import Modal from "./Modal";

import RateReviewRoundedIcon from "@mui/icons-material/RateReviewRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import SendIcon from "@mui/icons-material/Send";
import ChatIcon from "@mui/icons-material/Chat";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import RecordVoiceOverRoundedIcon from "@mui/icons-material/RecordVoiceOverRounded";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
// for panel
import MinimizeIcon from "@mui/icons-material/Minimize";
import MaximizeIcon from "@mui/icons-material/Maximize";
import FullscreenRoundedIcon from "@mui/icons-material/FullscreenRounded";

import Avatar from "@mui/material/Avatar";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

import Logout from "@mui/icons-material/Logout";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { getSpeechToText } from "api/space/speech-to-text";

import { primaryIconButton, primaryButton } from "theme/elements";
import { useHistory } from "react-router";
import { cs_addCandidate } from "redux/space/reducers/candidateSpace/candidateSpaceSlice";

import { defaultProfileImage } from "constants/production";
import ChatWindow from "components/Chat/ChatWindow";
import { updateChatWindowConfig } from "redux/space/reducers/chatWindowConfigSlice";

import { loggedUser } from "redux/dashboard/reducers/userSlice";

import { userLogin } from "api/auth";

import broadcastChannel from "utils/BroadcastChannel";

import AvatarViewer from "components/AvatarViewer";
import axios from "utils/axios";

import "./login.css";
import {
  useStyles,
  closeBtn,
  micButton,
  micRecordingButton,
  primaryBtn,
} from "./basicLayoutStyles";

import {
  getCandidateAssistantList,
  assistantChat,
} from "api/dashboard/assistant";

interface RouteParams {
  candidateId: string;
  // Add other parameters if needed
}

import { getResumeData } from "api/dashboard/parseResume";
import { getSceneByCreated } from "api/scene";
import { getCandidateDetails } from "api/dashboard/candidate";
import { initialContext, useSpaceContext } from "context/spaceContext";
import useRecorder from "hooks/recorder";
import DraggableComponent from "components/DraggableComponent";
import { AnyMapping } from "three";
import { FindUser } from "api/users";

const BasicLayout = (props): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const sceneContainerRef = useRef();

  const location = useLocation();

  const { candidateId } = useParams<RouteParams>();

  const { spaceContextData, setSpaceContextData } = useSpaceContext();

  const dispatch = useAppDispatch();
  const userId = useAppSelector(
    (state) => state?.userReducer?.user?.loggedUser?.userKey,
  );

  const logUser = useAppSelector(
    (state) => state?.userReducer?.user?.loggedUser,
  );

  const welcomeText = useAppSelector(
    (state) => state?.cs_avatarSpeechReducer?.cs_avatar?.welcomeText,
  );

  const userData = useAppSelector((state) => state?.userReducer?.user);

  useEffect(() => {
    if (welcomeText) dispatch(cs_addAvatarSpeech(welcomeText));
    return () => {};
  }, [welcomeText]);

  const [candidateUserId, setCandidateUserId] = useState<string>("");

  const [bot, setBot] = useState<any>();
  const [botId, setBotId] = useState();
  const [status, setStatus] = useState();
  const [msgPanel, setMsgPanel] = useState<boolean>(false);
  const [inputMessage, setInputMessage] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const [isChatBtnHovered, setIsChatBtnHovered] = useState(false);

  const [profileImage, setProfileImage] = useState<string>("");

  const [candidateMenuOpen, setCandidateMenuOpen] = useState<boolean>(false);
  const [candidateMenuOpenAnchorEl, setCandidateMenuOpenAnchorEl] =
    useState<any>();

  const [isMinimized, setIsMinimized] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleCandidateMenuOpen = (e: any) => {
    setCandidateMenuOpen(true);
    setCandidateMenuOpenAnchorEl(e.currentTarget);
  };

  const handleCandidateMenuClose = () => {
    setCandidateMenuOpen(false);
    setCandidateMenuOpenAnchorEl(null);
  };

  const handleChatBtnMouseEnter = () => {
    setIsChatBtnHovered(true);
  };

  const handleChatBtnMouseLeave = () => {
    setIsChatBtnHovered(false);
  };

  useEffect(() => {
    dispatch(updateChatWindowConfig({ isClosed: true, isMinimized: true }));

    return () => {};
  }, []);

  const handleChatBtnClick = () => {
    if (userId) {
      dispatch(updateChatWindowConfig({ isClosed: false, isMinimized: false }));
    } else {
      handleLoginClick();
    }
    handleCandidateMenuClose();
  };

  const [candidateData, setCandidateData] = useState<any>();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    const loggedInUserData = {
      user: "",
      role: "",
      email: "",
      userId: "",
      userKey: "",
      username: "",
      profile_photo: "",
    };
    broadcastChannel.postMessage(loggedInUserData);
    dispatch(loggedUser(loggedInUserData));
    handleClose();
  };

  const isScreeningExist = async (candidate: string): Promise<boolean> => {
    try {
      if (!candidate) {
        return false;
      }

      const response = await axios.get(
        "/screening/read?candidateId=" + candidate,
      );
      return response?.data?.data?.length > 0;
    } catch (error) {
      console.error("Error fetching screening data:", error);
      return false;
    }
  };

  const handleInterview = () => {
    if (logUser?.user === "candidate") {
      handleOpenInterviewModal();
    } else if (logUser?.user === "Admin" || logUser?.user === "user") {
      history.push(`/candidate_space/${candidateData?._id}/start`);
    } else {
      history.push(`/candidate_space/${candidateId}/start`);
    }
    handleCandidateMenuClose();
  };

  useEffect(() => {
    if (bot) {
      setBotId(bot[0]?.id);
    }

    return () => {};
  }, [bot]);

  const [resumeImage, setResumeImage] = useState();

  useEffect(() => {
    if (logUser) {
      const profileUrl = logUser?.profile_photo;
      if (profileUrl) {
        setProfileImage(profileUrl);
      } else if (logUser?.userKey) {
        getResumeData(logUser?.userKey)
          .then((response) => {
            setProfileImage(response?.data?.profile_image);
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    }

    return () => {};
  }, [logUser]);

  const fetchData = async () => {
    try {
      if (candidateId) {
        const candidateDetails: any = await getCandidateDetails(candidateId);

        if (candidateDetails?.data) {
          setCandidateUserId(candidateDetails?.data?.userId);
          setCandidateData(candidateDetails?.data);

          if (candidateDetails?.data?.userId) {
            const resumeData: any = await getResumeData(
              candidateDetails?.data?.userId,
            );

            const candidateUserData = await FindUser(
              candidateDetails?.data?.userId,
            );

            // get scene data
            await fetchSceneData(candidateDetails?.data?.userId);

            setResumeImage(candidateUserData?.data[0]?.profile_image);

            setSpaceContextData((prevState) => ({
              ...prevState,
              profile: {
                ...prevState.profile,
                candidate: candidateDetails?.data,
                userId: candidateDetails?.data?.userId,
                resumeData: {
                  ...resumeData?.data,
                  documents: candidateDetails?.data?.documents,
                  profile_videos: candidateDetails?.data?.profile_videos,
                  profile_resume_videos:
                    candidateDetails?.data?.profile_resume_videos,
                },
                candidateUserData: candidateUserData?.data[0],
              },
            }));
          }
        }
      }
    } catch (error) {
      console.error("Failed to fetch candidate details, Error : ", error);
    }
  };

  const fetchSceneData = async (userId) => {
    try {
      if (userId) {
        const response: any = await getSceneByCreated(userId);
        if (response?.data?.activeScene) {
          setSpaceContextData((prevState) => ({
            ...prevState,
            space: {
              avatar: response?.data?.activeScene?.avatar,
              camera: response?.data?.activeScene?.camera,
            },
          }));
        } else if (
          response?.data?.unpublished_data &&
          response?.data?.unpublished_data[0]
        ) {
          setSpaceContextData((prevState) => ({
            ...prevState,
            space: {
              avatar: response?.data?.unpublished_data[0]?.avatar,
              camera: response?.data?.unpublished_data[0]?.camera,
            },
          }));
        } else {
          // reset scene
          setSpaceContextData((prevState) => ({
            ...prevState,
            space: initialContext.spaceContextData.space,
          }));
        }
      }
    } catch (error) {
      console.error("Failed to fetch candidate scene details, Error : ", error);
    }
  };
  useEffect(() => {
    if (candidateId) {
      fetchData();
      // adding userId to session storage
      sessionStorage.setItem("userId", candidateId);
      // localStorage.setItem('broadcastUserId', candidateId);
    }

    return () => {};
  }, [candidateId]);

  // recorder
  const { recorderState, ...handlers } = useRecorder();
  const { blob } = recorderState;
  const { recordingMinutes, recordingSeconds, initRecording } = recorderState;
  const { startRecording, saveRecording } = handlers;

  const toggleMicrophone = () => {
    setInputMessage("");
    if (initRecording) {
      saveRecording();
    } else {
      startRecording();
    }
  };

  useEffect(() => {
    if (blob) {
      const formData = new FormData();
      formData.append("file", blob, "audio.ogg");
      getSpeechToText(formData)
        .then((res: any) => {
          if (res?.transcription) {
            setIsProcessing(true);
            setInputMessage(res?.transcription);
            handleSendClick(res?.transcription);
          }
        })
        .catch((err) => {
          console.error("speech to text error", err);
          dispatch(
            cs_addAvatarSpeech(
              "Sorry I didn't understand. Can you repeat that?",
            ),
          );
        });
    }
  }, [blob]);

  useEffect(() => {
    if (candidateUserId) {
      getCandidateAssistantList(candidateUserId)
        .then((response) => {
          setBot(response?.data);
        })
        .catch((err) => {
          console.error("failed to get bot", err);
        });
    }

    return () => {};
  }, [candidateUserId]);

  useEffect(() => {
    // shows message panel on recording start
    if (initRecording) {
      setMsgPanel(true);
    }

    return () => {};
  }, [initRecording]);

  const dispatchAvatarSpeech = (speechText) => {
    setIsProcessing(false);
    setInputMessage("");
    setMsgPanel(!msgPanel);
    dispatch(cs_addAvatarSpeech(speechText));
  };

  const handleSendClick = (messageText: string) => {
    console.log("messageText : ", messageText);
    const query = messageText.trim();
    console.log("query : ", query);
    if (!query) {
      return false;
    }

    setInputMessage("");
    setIsProcessing(true);
    const results = [{ id: botId }].map(
      (bot) => bot?.id && assistantChat(bot.id, query),
    );
    Promise.allSettled(results)
      .then((responses: any) => {
        console.log("avatar response: ", responses);
        const firstResponse = responses[0];

        if (firstResponse?.status === "fulfilled") {
          const speechText =
            firstResponse.value?.messages[0]?.content[0]?.text?.value || "";

          setSpaceContextData((prevState: any) => ({
            ...prevState,
            space: {
              ...prevState.space,
              avatar: {
                ...prevState.space.avatar,
                speechText: speechText,
              },
            },
          }));
          setMsgPanel(false);
          setIsProcessing(false);
        } else {
          setSpaceContextData((prevState: any) => ({
            ...prevState,
            space: {
              ...prevState.space,
              avatar: {
                ...prevState.space.avatar,
                speechText: "Sorry I'm not able to process your request",
              },
            },
          }));
          setMsgPanel(false);
          setIsProcessing(false);
        }
      })
      .catch((error) => {
        setSpaceContextData((prevState: any) => ({
          ...prevState,
          space: {
            ...prevState.space,
            avatar: {
              ...prevState.space.avatar,
              speechText: "Sorry I'm not able to process your request",
            },
          },
        }));
        setMsgPanel(false);
        setIsProcessing(false);
      });
  };

  const handleKeyPress = (event) => {
    console.log("Enter key pressed : ", event);
    if (event.key === "Enter") {
      handleSendClick(event.currentTarget.value);
    }
  };

  const [openInterviewModal, setOpenInterviewModal] = useState(false);

  const handleOpenInterviewModal = () => {
    setOpenInterviewModal(true);
  };
  const handleCloseInterviewModal = () => {
    setOpenInterviewModal(false);
  };

  const handleLoginClick = () => {
    history.push(`/candidate_space/${candidateData?._id}/login`);
  };

  const initialValues = { email: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      submit();
    }
  }, [formErrors]);

  //form validation handler
  const validate = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email) {
      errors.email = "Cannot be blank";
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid email format";
    }

    if (!values.password) {
      errors.password = "Cannot be blank";
    } else if (values.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
    }

    return errors;
  };

  //form submission handler
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmitting(true);
  };

  const submit = () => {
    if (formValues) {
      userLogin({
        email: formValues.email,
        password: formValues.password,
      })
        .then((data) => {
          setIsSubmitting(true);
          const userId = data?.response?._id;
          const matchToken = data?.token;
          const userType = data?.response?.userType;
          localStorage.setItem("userId", userId);
          localStorage.setItem("user_matchToken", matchToken);
          localStorage.setItem("user_type", userType);
          setFormValues(initialValues);

          const loggedInUserData = {
            user: userType,
            role: userType,
            email: data?.response?.email,
            userId,
            userKey: data?.id,
            username: data?.response?.name,
            profile_photo: data?.response?.profile_photo,
          };
          broadcastChannel.postMessage(loggedInUserData);
          // dispatch(loggedUser(loggedInUserData));
          setSpaceContextData((prevState) => ({
            ...prevState,
            profile: {
              ...prevState.profile,
              user: loggedInUserData,
            },
          }));
          // updateSnackbar(true, 1, "Logged in successfully", true);
          localStorage.setItem("user", JSON.stringify(loggedInUserData));
        })
        .catch((err) => {
          setErrorMessage("Login failed please check your email or password");
          console.error("Login failed : ", err);
        });
    }
  };

  return (
    <HelmetProvider>
      <ChatWindow
        mode="candidate"
        sender={userId}
        senderData={userData}
        recipient={candidateId}
        recipientData={spaceContextData?.profile}
        recipientName={spaceContextData?.profile?.candidate?.name}
        recipientImage={
          spaceContextData?.profile?.candidateUserData?.profile_image
        }
      />
      <div className="flex h-screen w-screen flex-1 flex-col bg-white dark:bg-black">
        <Helmet>
          <title>{candidateData?.name || "candidate"}'s - space</title>
          <link
            rel="shortcut icon"
            href={
              candidateData?.profile_image ||
              candidateData?.profile_photo ||
              defaultProfileImage ||
              "/assets/images/logo/apple-icon.png"
            }
          />
        </Helmet>
        {/* Top bar start */}
        <div className="m-2 flex flex-row items-start justify-between rounded-md bg-white p-2 shadow-md lg:items-center dark:bg-gray-800">
          <div className="flex flex-row text-center">
            <IconButton
              aria-label="back"
              onClick={history.goBack}
              sx={primaryIconButton}
              className="h-10 w-10 dark:text-white"
            >
              <ArrowBackIcon className="dark:text-white" />
            </IconButton>

            {location.pathname !== `/candidate_space/${candidateId}/start` && (
              <IconButton
                aria-label="home"
                sx={primaryIconButton}
                className="dark:text-white"
                onClick={(e) =>
                  history.push(`/candidate_space/${candidateId}/start`)
                }
              >
                <HomeOutlinedIcon />
              </IconButton>
            )}

            {logUser && Object.keys(logUser) ? (
              <div className="mx-0.5 flex flex-row items-center justify-center rounded-full bg-blue-300 p-0.5 shadow-md delay-75 hover:shadow-md dark:bg-blue-900">
                <Avatar
                  alt={logUser?.userName || "user"}
                  src={profileImage || defaultProfileImage}
                  className="m-0.5 h-6 w-6 cursor-pointer hover:shadow-md lg:mx-1"
                  onClick={handleClick}
                />
                <div className="mr-2 hidden flex-col lg:flex">
                  <span className="text-md text-ellipsis text-wrap font-poppins font-bold uppercase text-black dark:text-white">
                    {logUser?.username}
                  </span>
                  <span className="text-ellipsis text-wrap font-poppins text-sm text-black dark:text-white">
                    {logUser?.role}
                  </span>
                </div>
              </div>
            ) : (
              <div style={{ marginLeft: 10 }}>
                <Button
                  className="m-3 h-10 transform-none px-2 py-1 font-poppins text-blue-500"
                  onClick={() => handleLoginClick()}
                >
                  Login
                </Button>
              </div>
            )}
          </div>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>

          <Menu
            id="candidate-basic-menu"
            anchorEl={candidateMenuOpenAnchorEl}
            open={candidateMenuOpen}
            onClose={handleCandidateMenuClose}
            MenuListProps={{
              "aria-labelledby": "candidate-menu-basic-button",
              className: "dark:bg-slate-700 dark:text-white font-poppins",
            }}
            className="flex lg:hidden"
          >
            {(logUser?.user === "Admin" || logUser?.user === "user") &&
              isScreeningExist(candidateId) && (
                <MenuItem
                  data-testid="candidate-interview-btn"
                  onClick={handleInterview}
                >
                  <RecordVoiceOverRoundedIcon
                    sx={{ marginRight: "10px", color: "#651fff" }}
                  />
                  Interview
                </MenuItem>
              )}
            <MenuItem onClick={handleChatBtnClick}>
              <ChatRoundedIcon sx={{ marginRight: "10px", color: "#2979ff" }} />
              Message Now
            </MenuItem>
          </Menu>

          <div className="flex flex-row items-center justify-center text-center">
            <div
              onClick={handleCandidateMenuOpen}
              className="mx-0.5 flex flex-row items-center justify-center rounded-full bg-blue-300 p-0.5 dark:bg-blue-900"
            >
              <Avatar
                className="m-0.5 h-6 w-6 cursor-pointer text-black hover:shadow-md lg:mx-1 dark:text-white"
                src={
                  candidateData?.profile_image ||
                  candidateData?.profile_photo ||
                  resumeImage ||
                  defaultProfileImage
                }
              />
              <div className="mr-2 hidden flex-col px-1 lg:flex lg:w-24">
                <span className="text-md text-start font-poppins font-bold text-blue-500 dark:text-white">
                  {candidateData?.name}
                </span>
              </div>
            </div>

            {(logUser?.user === "Admin" || logUser?.user === "user") &&
              isScreeningExist(candidateId) && (
                <div className="hidden lg:flex">
                  <button
                    data-testid="candidate-interview-btn"
                    className="text-md group mx-2 hidden h-10 w-auto transform-none cursor-pointer items-center justify-center rounded-full border-0 bg-white px-3 text-center font-poppins text-blue-500 shadow-md outline-none transition-all delay-100 ease-in hover:shadow-xl lg:flex dark:bg-gray-700 dark:text-white"
                    onClick={handleInterview}
                  >
                    <RateReviewRoundedIcon className="mr-2 text-gray-800 dark:text-white" />
                    Interview
                  </button>
                </div>
              )}

            <button
              aria-label="chat"
              onClick={handleChatBtnClick}
              className="text-md group mx-2 hidden h-10 w-10 transform-none cursor-pointer items-center justify-center rounded-full border-0 bg-white px-2 text-center font-poppins text-blue-500 shadow-md outline-none transition-all delay-100 ease-in hover:w-auto hover:shadow-lg lg:flex dark:bg-gray-700"
            >
              <SendIcon />
              <span className="ml-2 hidden group-hover:inline">
                Message now
              </span>
            </button>
          </div>
        </div>
        {/* Top bar end */}

        <div
          className="flex h-screen max-h-screen w-full flex-1 flex-col transition-all delay-100 ease-in-out lg:flex-row"
          id="scene-wrapper"
        >
          {!isClosed && (
            <div
              className={`absolute bottom-3 right-1 z-50 m-2 flex flex-col bg-white dark:bg-gray-800 ${
                !isMinimized ? "h-60 w-40" : "h-10 w-10"
              } ${!isMinimized ? "lg:relative lg:bottom-auto lg:right-auto lg:m-1 lg:h-[calc(100vh-100px)] lg:max-h-[calc(100vh-100px)] lg:min-h-[calc(100vh-100px)] lg:w-[30rem]" : ""}`}
              style={{
                borderRadius: "0.5rem",
              }}
              id="avatar-scene-section"
            >
              <button
                className={`group absolute right-0 top-0 z-50 m-1 flex h-10 w-auto min-w-10 items-center justify-center rounded-full border-none  shadow-md outline-none hover:shadow-lg lg:hidden ${isMinimized ? " bg-white dark:bg-gray-700 " : "bg-white/[0.5] shadow-md blur-md "}`}
                onClick={toggleMinimize}
              >
                {isMinimized ? (
                  <div className="flex items-center">
                    <FullscreenRoundedIcon className="text-gray-600 dark:text-gray-300" />
                    <span className="mx-2 hidden w-28 text-gray-600 group-hover:inline-block dark:text-gray-300">
                      View Avatar
                    </span>
                  </div>
                ) : (
                  <MinimizeIcon className="text-red-500" />
                )}
              </button>

              <AvatarViewer
                avatar={spaceContextData?.space?.avatar}
                camera={spaceContextData?.space?.camera}
                viewMode={true}
                style={{ borderRadius: "0.5rem" }}
                onReady={(status: boolean) =>
                  console.log("Avatar ready on basic layout")
                }
                hidden={isMinimized}
              />

              <div className="absolute right-1 top-1 hidden lg:flex">
                <button
                  className="m-1 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-none bg-white/[0.25] text-red-600 shadow-sm outline-none hover:bg-white hover:shadow-md"
                  onClick={() => setIsClosed(true)}
                >
                  <CloseRoundedIcon className="text-inherit" />
                </button>
              </div>

              {/* message panel */}
              {!isMinimized && (
                <div className="absolute bottom-0 left-0 right-0 flex flex-col-reverse items-center justify-between rounded-md shadow-lg lg:m-4 lg:h-20 lg:w-[27rem] lg:flex-row lg:bg-white lg:p-2 lg:dark:bg-gray-700">
                  {botId && (
                    <div className="flex flex-row font-poppins lg:pl-5">
                      <Tooltip title={msgPanel ? "Close" : "Chat"} arrow>
                        <button
                          onClick={() => setMsgPanel(!msgPanel)}
                          className="m-1 flex cursor-pointer flex-row rounded-full border-0 bg-transparent p-1 font-poppins outline-0 transition-all delay-500 ease-in-out hover:shadow-md"
                        >
                          {msgPanel ? (
                            <CloseRoundedIcon className="text-red-500" />
                          ) : (
                            <ChatIcon className="text-gray-800 dark:text-white" />
                          )}
                        </button>
                      </Tooltip>

                      <div className="flex flex-1 flex-row items-center justify-center">
                        <Tooltip
                          title={initRecording ? "Stop Recording" : "Talk"}
                          arrow
                        >
                          <button
                            aria-label="mike"
                            onClick={toggleMicrophone}
                            className={`${initRecording ? "bg-red-500 text-white shadow-md shadow-red-600" : "bg-white text-gray-600"} m-1 flex cursor-pointer flex-row rounded-full border-0 p-1 font-poppins outline-0 transition-all delay-500 ease-in-out hover:shadow-md`}
                          >
                            {initRecording ? (
                              <StopRoundedIcon style={{ color: "inherit" }} />
                            ) : (
                              <KeyboardVoiceIcon style={{ color: "inherit" }} />
                            )}
                          </button>
                        </Tooltip>
                        <div className={classes.recorderTimeWrapper}>
                          {initRecording ? (
                            <>
                              <p className={classes.recorderText}>
                                {recordingMinutes}
                              </p>
                              <p className={classes.recorderText}>:</p>
                              <p className={classes.recorderText}>
                                {recordingSeconds}
                              </p>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {msgPanel ? (
                    <div className="hidden flex-1 flex-row items-center lg:flex">
                      <input
                        type="text"
                        defaultValue={inputMessage}
                        onBlur={(e) => setInputMessage(e.currentTarget.value)}
                        className="z-50 m-1 hidden w-full rounded-md border-0 p-3 text-black outline-0 lg:flex dark:bg-gray-800 dark:text-white "
                        placeholder="Type your message"
                        onKeyDown={handleKeyPress}
                      />
                      <div className="mx-2">
                        {isProcessing ? (
                          <CircularProgress size={18} />
                        ) : (
                          <IconButton
                            aria-label="send"
                            onClick={() => handleSendClick(inputMessage)}
                            sx={primaryBtn}
                          >
                            <SendIcon />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  ) : (
                    <PerfectScrollbar className="hidden max-h-16 flex-1 text-wrap break-words pt-1 font-poppins text-black lg:flex dark:text-white">
                      <div className="flex flex-1 items-center text-wrap break-words font-poppins text-black dark:text-white">
                        {spaceContextData?.space?.avatar?.speechText}
                      </div>
                    </PerfectScrollbar>
                  )}
                </div>
              )}
              {/* message panel end */}
            </div>
          )}
          {/* close end */}

          <div
            className="m-2 flex h-full max-h-[calc(100vh-120px)] w-auto flex-1 rounded-md bg-white p-2 shadow-md lg:min-h-[34rem] dark:bg-gray-800"
            id="details-wrapper"
          >
            {props.children}
          </div>
        </div>
      </div>

      <Modal
        title="Permission Denied"
        open={openInterviewModal}
        handleClose={handleCloseInterviewModal}
      >
        <div className={classes.modalContainer}>
          <Alert severity="error">
            <>
              We're sorry, but you do not have the necessary permissions to
              perform this action. Access is restricted to authorized company
              admins or users only. —{" "}
              <strong>Please contact your system administrator!</strong>
            </>
          </Alert>
        </div>
      </Modal>
    </HelmetProvider>
  );
};

export default BasicLayout;
