import React, { useState, useEffect, useRef } from "react";
import {
  Person,
  ExpandMore,
  Minimize,
  Send,
  HorizontalRuleRounded,
} from "@mui/icons-material";

import {
  Avatar,
  TextField,
  Button,
  InputBase,
  InputLabel,
  FormControl,
  IconButton,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { alpha, styled } from "@mui/material/styles";
import { DateTime } from "luxon";

import Draggable from "react-draggable";

import "./chat-window-styles.css";
import {
  S3_BUCKET_URL,
  SERVER_URL,
  defaultProfileImage,
  isTesting,
} from "constants/production";
import { deleteButton, primaryIconButton } from "theme/elements";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

// socket
import {
  initiateSocket,
  subscribeToEvent,
  sendEvent,
  disconnectSocket,
} from "utils/socket.io";
import { updateUserNewChat } from "redux/dashboard/reducers/chatSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getUserChat } from "api/dashboard/conversation";
import CircularProgressLoader from "components/space/Progress/CircularProgressLoader";

import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

import { unionBy, sortBy } from "lodash";
import ChatComponent from "../ChatComponent";
import { updateChatWindowConfig } from "redux/space/reducers/chatWindowConfigSlice";

const avatarStyle = {
  width: 30,
  height: 30,
  objectFit: "contain",
  "&:hover": {
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  },
};
interface IMessage {
  _id?: string;
  sender: string;
  recipient: string;
  message_type: string;
  message: {
    text: string;
    send_by: string;
  };
  createdAt?: string;
}
type Props = {
  mode: string;
  recipient: string;
  recipientData: any;
  sender: string;
  senderData: any;
  recipientName: string;
  recipientImage: string;
};

const ChatWindow: React.FC = ({
  mode,
  recipient,
  recipientData,
  sender,
  senderData,
  recipientName,
  recipientImage,
}: Props) => {
  const dispatch = useAppDispatch();
  const socketRef = useRef(null);

  const [page, setPage] = useState(0);

  // console.log("recipientData ", recipientData, " senderData: ", senderData);

  const config = useAppSelector(
    (state) => state?.cs_chatWindowConfigReducer?.cs_chatWindowConfig,
  );

  const [chatWindowClasses, setChatWindowClasses] = useState<string>("closed");
  const positionRef = useRef({ x: 0, y: 0 });

  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<IMessage[]>([]);

  const [socketMessage, setSocketMessage] = useState();
  const [currentChatPage, setCurrentChatPage] = useState(0);
  const [isChatFetching, setIsChatFetching] = useState(false);
  const [isFirstFetch, setIsFirstFetch] = useState(false);
  const [isLastMessageReceived, setIsLastMessageReceived] = useState(false);

  const handleMinimize = () => {
    dispatch(updateChatWindowConfig({ isMinimized: true }));
  };

  const handleExpand = () => {
    dispatch(updateChatWindowConfig({ isMinimized: false }));
  };

  const handleWindowClose = () => {
    dispatch(updateChatWindowConfig({ isClosed: true }));
  };

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    if (message && message.trim().length) {
      const messageData: IMessage = {
        sender,
        recipient,
        message_type: "chat",
        message: {
          text: message,
          send_by: sender,
        },
      };

      sendEvent(socketRef.current, "chat", messageData, senderData?.token);
      messageData._id = "temp_" + Math.random() * 1000;
      messageData.createdAt = DateTime.now().toISO();
      setMessages((prevState) => [...prevState, messageData]);
      setMessage("");
    }
  };

  const setupEventListeners = (socket) => {
    subscribeToEvent(socket, "joined", (response: IMessage) => {
      console.log("socket event: joined, response: ", response);
    });

    subscribeToEvent(socket, "chat_message", (response: IMessage) => {
      console.log("socket chat_message: ", response);
      setMessages((prevState) => [...prevState, response]);
    });
  };

  useEffect(() => {
    if (senderData?.token) {
      const room = mode === "company" ? sender : recipient;

      const socket = initiateSocket(SERVER_URL, senderData?.token, room);
      socketRef.current = socket;

      // Wait for the socket to connect before subscribing to events
      if (socket.connected) {
        // If already connected, subscribe immediately
        setupEventListeners(socket);
      } else {
        // Otherwise, wait for the 'connect' event
        socket.on("connect", () => {
          setupEventListeners(socket);
        });
      }
    }

    return () => {
      disconnectSocket(socketRef.current);
    };
  }, [senderData?.token]);

  const generateWindowClass = (minimize: boolean, close: boolean): string => {
    if (close) {
      return "closed";
    }
    if (minimize) {
      return "minimized";
    }
    return "";
  };

  useEffect(() => {
    const windowStatus = generateWindowClass(
      config?.isMinimized,
      config?.isClosed,
    );
    setChatWindowClasses(windowStatus);

    return () => {};
  }, [config]);

  const fetchConversationData = (
    sender: string,
    recipient: string,
    page = 0,
  ) => {
    setIsChatFetching(true);
    // fetch user chat
    getUserChat(sender, recipient, page)
      .then((data) => {
        setIsChatFetching(false);
        if (data && data.length) {
          setCurrentChatPage(page);
          const chatMessages = data.filter((el) => el?.message_type === "chat");

          if (messages && messages.length) {
            // merge new messages with existing message list and sorted by createdAt
            const mergedArray = unionBy(messages, chatMessages, "_id");
            const sortedArray = sortBy(mergedArray, "createdAt");
            setMessages(sortedArray);
            // control scroll bar position
            if (chatMessages && chatMessages.length) {
              if (chatMessages.length < 10) {
                setIsLastMessageReceived(true);
              }
            }
          } else {
            // no messages exist yet
            setMessages(sortBy(chatMessages, "createdAt"));
          }
        }
      })
      .catch((error) => {
        setIsChatFetching(false);
        console.error("Failed to get user chat : ", error);
      });
  };

  const ps = useRef(null);

  const [loadMore, setLoadMore] = useState(false);

  const handleChatMessageScroll = (e) => {
    const element = e.target;
    if (element.scrollTop < 50) {
      setLoadMore(true);
    } else {
      setLoadMore(false);
    }
  };

  // fetch messages on first render
  useEffect(() => {
    if (sender && recipient) {
      fetchConversationData(sender, recipient, currentChatPage);
    }

    return () => {
      setMessages([]);
    };
  }, [sender, recipient, currentChatPage]);

  return (
    <div className="chat-window-wrapper">
      <div
        className={`chat-window bg-white dark:bg-gray-700 ${chatWindowClasses}`}
      >
        <div className="chat-header bg-white dark:bg-gray-700">
          <div className="chat-header-wrapper">
            <Avatar
              className="profile-picture"
              src={recipientImage || defaultProfileImage}
              sx={avatarStyle}
            />
            <div className="text-md font-poppins font-bold text-black dark:text-white">
              {recipientName}
            </div>
          </div>
          {config?.isMinimized ? (
            <button
              className="flex h-8 w-8 items-center justify-center rounded-full border-none bg-gray-700/[0.4] text-center text-white shadow-sm outline-none hover:bg-blue-500 hover:text-white"
              onClick={handleExpand}
            >
              <ExpandMore />
            </button>
          ) : (
            <button
              className="flex h-8 w-8 items-center justify-center rounded-full border-none bg-gray-700/[0.4] text-center text-white shadow-sm outline-none hover:bg-blue-500 hover:text-white"
              onClick={handleMinimize}
            >
              <HorizontalRuleRounded />
            </button>
          )}
          <button
            className="flex h-8 w-8 items-center justify-center rounded-full border-none bg-gray-700/[0.4] text-center text-red-500 shadow-sm outline-none hover:bg-red-500 hover:text-white"
            onClick={handleWindowClose}
          >
            <CloseRoundedIcon />
          </button>
        </div>
        {isChatFetching && (
          <div className="chat-fetching">
            Fetching <div className="loader"></div>
          </div>
        )}

        <div className="chat-body">
          <PerfectScrollbar
            className="chat-body"
            onScroll={handleChatMessageScroll}
            containerRef={(el) => (ps.current = el)}
          >
            <ChatComponent
              messageList={messages}
              sender={sender}
              recipient={recipient}
            />
          </PerfectScrollbar>
        </div>

        <div className="chat-footer bg-white dark:bg-gray-700">
          <input
            id="name-input"
            className="m-1 ml-2 h-8 w-full rounded-md border-none bg-white p-1 px-2 outline-none dark:bg-gray-800 dark:text-white"
            placeholder="Type a message"
            value={message}
            onKeyPress={handleKeyPress}
            onChange={handleMessageChange}
            type="text"
            name="message"
          />

          <IconButton
            sx={{ ...primaryIconButton, color: "#007bff" }}
            onClick={handleSendMessage}
          >
            <Send />
          </IconButton>
        </div>
      </div>

      <div
        className={`chat-popper-container ${chatWindowClasses}`}
        onClick={handleExpand}
      >
        <div>
          <Avatar
            src={recipientImage || defaultProfileImage}
            sx={{ width: 40, height: 40 }}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;
