import React from "react";
import { DateTime } from "luxon";

import Lottie from "lottie-react";

import "./chat-component.css";
import { Skeleton } from "@mui/material";

const formatMessageDate = (message) => {
  const messageDate = DateTime.fromISO(message.createdAt);
  return messageDate.toLocaleString(DateTime.TIME_SIMPLE);
};

const formatTimelineLabel = (messageDate) => {
  const today = DateTime.local().startOf("day");
  if (messageDate.hasSame(today, "day")) {
    return "Today";
  } else {
    return messageDate.toLocaleString(DateTime.DATE_MED);
  }
};

const getRandomWidth = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const ChatComponent = ({ messageList, sender }) => {
  if (!messageList || messageList.length === 0) {
    return (
      <div className="flex flex-1 flex-col p-1">
        {Array.from({ length: 3 }).map((_, index) => (
          <React.Fragment key={index}>
            <div className="flex flex-row">
              <Skeleton variant="circular" width={40} height={40} />
              <div className="flex flex-col p-1">
                <Skeleton
                  variant="rounded"
                  width={getRandomWidth(50, 220)}
                  height={10}
                  className="mb-1"
                />
                <Skeleton
                  variant="rounded"
                  width={getRandomWidth(80, 150)}
                  height={10}
                  className="mb-1"
                />
                <Skeleton
                  variant="rounded"
                  width={getRandomWidth(100, 180)}
                  height={10}
                  className="mb-1"
                />
              </div>
            </div>

            <div className="flex flex-row-reverse">
              <Skeleton variant="circular" width={40} height={40} />
              <div className="flex flex-col items-end p-1">
                <Skeleton
                  variant="rounded"
                  width={getRandomWidth(50, 120)}
                  height={10}
                  className="mb-1"
                />
                <Skeleton
                  variant="rounded"
                  width={getRandomWidth(80, 150)}
                  height={10}
                  className="mb-1"
                />
                <Skeleton
                  variant="rounded"
                  width={getRandomWidth(100, 180)}
                  height={10}
                  className="mb-1"
                />
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  }

  const formattedMessages = messageList.map((item, index) => {
    const previousMessage = messageList[index - 1];
    const nextMessage = messageList[index + 1];
    const messageType = item?.sender === sender ? "outgoing" : "incoming";

    const messageDate = DateTime.fromISO(item.createdAt);
    let timelineLabel;

    let messageTypeStyle = messageType;
    const classList = ["chat-message-bubble", messageType];

    if (previousMessage && previousMessage.createdAt) {
      const previousMessageDate = DateTime.fromISO(previousMessage.createdAt);
      if (previousMessageDate.hasSame(messageDate, "day")) {
        if (index === 0) {
          classList.push("Today");
        }
      } else {
        classList.push("date-label");
        timelineLabel = formatTimelineLabel(messageDate);
      }
    }

    if (index === 0) {
      if (DateTime.now().hasSame(messageDate, "day")) {
        classList.push("Today");
      } else {
        classList.push("date-label");
        timelineLabel = formatTimelineLabel(messageDate);
      }
    }

    if (previousMessage) {
      if (
        previousMessage.sender === item.sender ||
        previousMessage.recipient === item.recipient
      ) {
        classList.push("follow-up");
      }
    }

    if (nextMessage) {
      if (
        item.sender !== nextMessage.sender ||
        item.recipient !== nextMessage.recipient
      ) {
        classList.push("end");
      }

      const nextMessageDate = DateTime.fromISO(nextMessage.createdAt);
      if (!messageDate.hasSame(nextMessageDate, "day")) {
        classList.push("end");
      }
    } else {
      classList.push("end");
    }

    return (
      <div className={`message-wrapper ${messageType}`} key={item.createdAt}>
        {timelineLabel && (
          <div className="chat-timeline-indicator font-poppins">
            <div className="timeline-text  bg-gray-300 text-black shadow-md dark:bg-gray-800 dark:text-gray-200">
              {timelineLabel}
            </div>
          </div>
        )}

        <div className={`${classList.join(" ")} font-poppins shadow-sm`}>
          <div className="message-text font-poppins">{item?.message?.text}</div>
        </div>
        {classList.includes("end") && (
          <div className="time font-poppins">{formatMessageDate(item)}</div>
        )}
      </div>
    );
  });

  return formattedMessages;
};

export default ChatComponent;
