import React from "react";

const CompanyStartScreen = React.lazy(() => import("views/space/CompanyStart"));

const DepartmentScreen = React.lazy(
  () => import("views/space/DepartmentScreen"),
);
const JobRecommendationScreen = React.lazy(
  () => import("views/space/JobRecommendationScreen"),
);

const JobListing = React.lazy(
  () => import("views/space/JobRecommendationScreen/JobListing"),
);
const DepartmentJobsScreen = React.lazy(
  () => import("views/space/DepartmentJobsScreen"),
);
const JobListScreen = React.lazy(() => import("views/space/JobListScreen"));
const JobDetailsScreen = React.lazy(
  () => import("views/space/JobDetailsScreen"),
);

const InterviewVideoScreen = React.lazy(
  () => import("views/space/VideoScreen"),
);
const InterviewScreen = React.lazy(() => import("views/space/interviewScreen"));
const SubmitResponseScreen = React.lazy(
  () => import("views/space/SubmitResponseScreen"),
);
const companySpaceRegisterScreen = React.lazy(
  () => import("views/space/CompanySpaceRegisterScreen"),
);
const ScreeningStartScreen = React.lazy(
  () => import("views/space/ScreeningStartScreen"),
);
const ScreeningScreen = React.lazy(
  () => import("views/space/CompanyScreening"),
);
const TechnicalScreen = React.lazy(() => import("views/space/TechnicalScreen"));
const UploadScreen = React.lazy(() => import("views/space/UploadScreen"));
const LoginScreen = React.lazy(() => import("views/space/CompanyLoginScreen"));
const ResetPassword = React.lazy(
  () => import("views/space/CompanyLoginScreen/ResetPassword"),
);

const Screening = React.lazy(() => import("views/space/Screening"));
const ScreeningLogin = React.lazy(() => import("views/space/Screening/login"));
const Interview = React.lazy(() => import("views/space/Interview"));
const InterviewLogin = React.lazy(() => import("views/space/Interview/login"));
const Technical = React.lazy(() => import("views/space/TechnicalInterview"));
const TechnicalLogin = React.lazy(
  () => import("views/space/TechnicalInterview/login"),
);

const DepartmentShare = React.lazy(
  () => import("views/space/Share/Department"),
);
const JobShare = React.lazy(() => import("views/space/Share/Job"));

const candidateLogin = React.lazy(
  () => import("views/space/candidateLogin/LoginFormPage"),
);

const CompanySpaceLayout = React.lazy(
  () => import("layouts/Space/CompanySpaceLayout"),
);

const companySpaceRoutes = [
  {
    path: "/company_space/company/start",
    component: CompanyStartScreen,
    layout: CompanySpaceLayout,
    exact: true,
  },
  {
    path: "/company_space/department",
    component: DepartmentScreen,
    layout: CompanySpaceLayout,
    exact: true,
  },
  {
    path: "/company_space/resume/upload",
    component: JobRecommendationScreen,
    layout: CompanySpaceLayout,
    exact: true,
  },
  {
    path: "/company_space/jobs/recommended",
    component: JobListing,
    layout: CompanySpaceLayout,
    exact: true,
  },
  {
    path: "/company_space/department/job/:departmentId",
    component: DepartmentJobsScreen,
    layout: CompanySpaceLayout,
  },
  {
    path: "/company_space/job/list",
    component: JobListScreen,
    layout: CompanySpaceLayout,
    exact: true,
  },
  {
    path: "/company_space/job/details/:jobId/:shared?",
    component: JobDetailsScreen,
    layout: CompanySpaceLayout,
  },
  {
    path: "/company_space/job/details/:jobId/:companyId",
    component: JobDetailsScreen,
    layout: CompanySpaceLayout,
  },
  {
    path: "/company_space/interview/video",
    component: InterviewVideoScreen,
    layout: CompanySpaceLayout,
    exact: true,
    redirectPath: "/company_space/company/start",
    protected: true,
  },
  {
    path: "/company_space/interview",
    component: InterviewScreen,
    layout: CompanySpaceLayout,
    exact: true,
    redirectPath: "/company_space/company/start",
    protected: true,
  },
  {
    path: "/company_space/technical",
    component: TechnicalScreen,
    layout: CompanySpaceLayout,
    exact: true,
    redirectPath: "/company_space/company/start",
    protected: true,
  },
  {
    path: "/company_space/uploads",
    component: UploadScreen,
    layout: CompanySpaceLayout,
    exact: true,
    redirectPath: "/company_space/company/start",
    protected: true,
  },
  {
    path: "/company_space/submit/response",
    component: SubmitResponseScreen,
    layout: CompanySpaceLayout,
    exact: true,
  },
  {
    path: "/company_space/register/:jobId",
    component: companySpaceRegisterScreen,
    layout: CompanySpaceLayout,
  },
  {
    path: "/company_space/login/:jobId",
    component: LoginScreen,
    layout: CompanySpaceLayout,
  },
  {
    path: "/company_space/screening/start/:jobId",
    component: ScreeningStartScreen,
    layout: CompanySpaceLayout,
    redirectPath: "/company_space/company/start",
    protected: true,
  },
  {
    path: "/company_space/screening",
    component: ScreeningScreen,
    layout: CompanySpaceLayout,
    exact: true,
    redirectPath: "/company_space/company/start",
    protected: true,
  },
  {
    path: "/company_space/candidate/reset/password",
    component: ResetPassword,
    layout: CompanySpaceLayout,
    exact: true,
  },
  {
    path: "/company_space/screening/login/:candidateId/:jobId/:companyId/",
    component: ScreeningLogin,
    layout: CompanySpaceLayout,
  },
  {
    path: "/company_space/start/screening/:companyId/:jobId/:candidateId",
    component: Screening,
    layout: CompanySpaceLayout,
  },
  {
    path: "/company_space/interview/login/:candidateId/:jobId/:companyId/",
    component: InterviewLogin,
    layout: CompanySpaceLayout,
  },
  {
    path: "/company_space/start/interview/:companyId/:jobId/:candidateId",
    component: Interview,
    layout: CompanySpaceLayout,
  },
  {
    path: "/company_space/technical/login/:candidateId/:jobId/:companyId/",
    component: TechnicalLogin,
    layout: CompanySpaceLayout,
  },
  {
    path: "/company_space/start/technical/:companyId/:jobId/:candidateId",
    component: Technical,
    layout: CompanySpaceLayout,
  },
  {
    path: "/company_space/share/company/:companyId/department/:departmentId/job/:jobId",
    component: JobShare,
    layout: CompanySpaceLayout,
  },
  {
    path: "/company_space/share/company/:companyId/department/:departmentId",
    component: DepartmentShare,
    layout: CompanySpaceLayout,
  },

  {
    path: "/company_space/login",
    component: candidateLogin,
    layout: CompanySpaceLayout,
  },
];

export default companySpaceRoutes;
