import axios from "utils/axios";

type getSpeechParams = {
  text: string;
  voice: string;
};

export const getSpeech = ({ text, voice }: getSpeechParams) =>
  new Promise((resolve, reject) => {
    console.log('speech api : ', text)
    axios
      .post("/api/tts", { text, voice }, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
