export const S3_BUCKET_URL = "https://virtual-job-fair-file.s3.amazonaws.com";

export const SERVER_URL =
  process.env.REACT_APP_ENV === "dev"
    ? process.env.REACT_APP_DEV_SERVER
    : process.env.REACT_APP_PROD_SERVER;

export const SUPPORT_ASSISTANT_ID="asst_PdViLxaPFjaZtVSEzC3434mv"

export const PREVIEW_SERVER_URL = "https://localhost:3000";
export const CANDIDATE_PORTAL_URL = "https://bellohire.com";

export const defaultProfileImage =
  "https://img.freepik.com/free-psd/3d-illustration-person_23-2149436192.jpg";

export const GOOGLE_CAPTCHA_KEY = "6LckxkknAAAAADvomLoQrDCIXhB8ClIaoHg7SAWJ";

export const isTesting =
  process.env.REACT_APP_ENV === "testing" || process.env.REACT_APP_ENV === "dev"
    ? true
    : false;

export const DEFAULT_AVATAR_ID = "65dda7440e1491018e233f72";

export const DEFAULT_AVATAR =
  "https://models.readyplayer.me/65dda7440e1491018e233f72.glb";

export const DEFAULT_AVATAR_BACKGROUND =
  "https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/4k/brown_photostudio_06_4k.hdr";

export const JOB_DESCRIPTION =
  "https://job-description-ai-ejn4htt3oa-uc.a.run.app";

export const DefaultAvatarConfig = {
  camera: {
    cameraPosition: JSON.stringify([
      -0.7159254375517097, -2.8387461548019393, 39.5811178794745,
    ]),
    orbitalTarget: JSON.stringify([
      0.9903149785300269, -2.838746154801942, -1.3378769076385122,
    ]),
  },
  avatar: {
    url: DEFAULT_AVATAR,
    type: "female",
    position: {
      x: -2,
      y: -9,
      z: 28,
    },
    rotate: {
      x: 0,
      y: 6.3,
      z: 0,
    },
    scale: 6.0,
    speechVoice: "en-US-EmmaNeural",
    speechText: "",
    welcomeText: "Hi, Welcome",
    background: {
      id: "a443b8a0a2314a55ae5dee4dd6a151a0",
      title: "White round exhibition gallery",
      author: {
        name: "ChristyHsu",
        url: "https://sketchfab.com/ida61xq?utm_medium=embed&utm_campaign=share-popup&utm_content=",
      },
      file: "white_round_exhibition_gallery_1k.glb",
      url: "https://sketchfab.com/models/a443b8a0a2314a55ae5dee4dd6a151a0/embed?autostart=0&autospin=0&camera=0&preload=1&ui_hint=0&dnt=1",
    },
    room: {
      position: {
        x: 0,
        y: 4.4,
        z: -1.5,
      },
      scale: 5.1,
      rotate: {
        x: 0,
        y: 0,
        z: 0,
      },
    },
  },
};
