import React, { useState, useEffect, useRef, useCallback } from "react";
import { Send } from "@mui/icons-material";
import { FormControlLabel, IconButton, Switch } from "@mui/material";
import { DateTime } from "luxon";
import Draggable from "react-draggable";
import "./support-chat-window-styles.css";
import { primaryIconButton } from "theme/elements";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import ChatComponent from "../ChatComponent";
import ChatIcon from "./ChatIcon";
import { createSupportChatMessage } from "api/supportChat";
import { SERVER_URL, SUPPORT_ASSISTANT_ID } from "constants/production";
import {
  disconnectSocket,
  initiateSupportChatSocket,
  sendEvent,
  subscribeToEvent,
} from "utils/socket.io";

interface IMessage {
  _id?: String | Number;
  sender: String;
  recipient: String;
  message_type: String;
  message: {
    text: String;
    send_by: String;
  };
  createdAt?: String;
}

interface IAssistant {
  _id?: String;
  id?: String;
  description?: String;
  file_ids?: Array<String>;
  instructions?: String;
  model?: String;
  fileNames?: [];
  name?: String;
  tools?: [
    {
      type: String;
    },
    {
      type: String;
    },
  ];
  createdAt?: String;
  updatedAt?: String;
}

type Props = {
  assistant: IAssistant;
  sender?: String;
  senderToken?: String;
  account?: String;
  open: boolean;
  onClose: (value: boolean) => void;
};

const firstMessages = [
  {
    _id: 1,
    sender: SUPPORT_ASSISTANT_ID,
    recipient: "user",
    message_type: "message",
    message: {
      text: "Hello",
      send_by: SUPPORT_ASSISTANT_ID,
    },
    createdAt: DateTime.now().toString(),
  },
  {
    _id: 2,
    sender: SUPPORT_ASSISTANT_ID,
    recipient: "user",
    message_type: "message",
    message: {
      text: "How can I assist you today?",
      send_by: SUPPORT_ASSISTANT_ID,
    },
    createdAt: DateTime.now().toString(),
  },
];

const ChatWindow: React.FC<Props> = ({
  assistant,
  sender,
  senderToken,
  account,
  open,
  onClose,
}) => {
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [isChatFetching, setIsChatFetching] = useState(false);
  const [isLiveAgent, setIsLiveAgent] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const ps = useRef(null);

  const socketRef = useRef(null);

  useEffect(() => {
    if (senderToken) {
      const room = "support_" + sender;

      // Initialize socket and store the instance in the ref
      const socket = initiateSupportChatSocket(SERVER_URL, senderToken, room);
      socketRef.current = socket;

      // Wait for the socket to connect before subscribing to events
      if (socket.connected) {
        // If already connected, subscribe immediately
        setupEventListeners(socket);
      } else {
        // Otherwise, wait for the 'connect' event
        socket.on("connect", () => {
          setupEventListeners(socket);
        });
      }

      // Clean up on component unmount
      return () => {
        disconnectSocket(socketRef.current);
      };
    }
  }, [senderToken]);

  const setupEventListeners = (socket) => {
    subscribeToEvent(socket, "support_chat_user_joined", (response) => {
      // console.log("support_chat_user_joined event received");
      // console.log("support chat connected, response: ", response);
    });

    subscribeToEvent(socket, "support_chat_typing", (response) => {
      // console.log("event: support_chat_typing: ", response);
      setIsTyping(true);
    });

    subscribeToEvent(socket, "support_chat", (response) => {
      setIsTyping(false);
      setMessages((prevState) => [...prevState, response]);
    });
  };

  useEffect(() => {
    if (open) {
      let delay = 0;
      firstMessages.forEach((msg, index) => {
        setTimeout(() => {
          if (!messages.includes(msg)) {
            setMessages((prevMessages) => [...prevMessages, msg]);
          }
        }, delay);
        delay += 1000; // 1-second delay between messages
      });
    }
    setIsWindowOpen(open);
  }, [open]);

  const handleWindowClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSendMessage(event.currentTarget.value);
    }
  };

  const handleSendMessage = async (messageText: string) => {
    if (messageText && messageText.trim().length) {
      const messageData = {
        sender,
        recipient: SUPPORT_ASSISTANT_ID,
        message_type: "support_message_incoming",
        message: { text: messageText, send_by: account },
        account,
        createdAt: DateTime.now().toString(),
      };
      setMessages((prevMessages) => [...prevMessages, messageData]);
      setMessage("");

      sendEvent(
        socketRef.current,
        "support_chat_message",
        messageData,
        senderToken,
      );
      // await createSupportChatMessage(messageData);
    }
  };

  const handleInputMessage = (event) => {
    setMessage(event.currentTarget.value);
  };

  return (
    <Draggable>
      <div
        className="absolute bottom-5 right-1 z-50 w-[325px] flex-col rounded-2xl bg-white shadow-lg transition-all delay-75 ease-in dark:bg-gray-700"
        style={{ display: isWindowOpen ? "flex" : "none" }}
        id="support-chat-window"
      >
        <div
          className="mb-2 flex items-center justify-between rounded-t-2xl bg-white p-3 shadow-sm dark:bg-gray-700"
          id="chat-header"
        >
          <div
            className="flex flex-1 flex-row items-center"
            id="chat-header-wrapper"
          >
            <ChatIcon size={24} />
            <div className="text-md font-poppins font-bold text-black dark:text-white">
              Support AI Assistant
            </div>
          </div>

          <div className="flex items-center justify-between px-2">
            <FormControlLabel
              control={
                <Switch
                  color={"success"}
                  checked={isLiveAgent}
                  onChange={() => setIsLiveAgent(!isLiveAgent)}
                  size="small"
                />
              }
              label={isLiveAgent ? "Agent" : ""}
              className="font-poppins text-xs text-black dark:text-white"
            />
          </div>

          <button
            className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-none bg-gray-700/[0.4] text-center text-red-500 shadow-sm outline-none hover:bg-red-500 hover:text-white hover:shadow-md hover:shadow-red-500"
            onClick={handleWindowClose}
          >
            <CloseRoundedIcon />
          </button>
        </div>
        {isChatFetching && (
          <div
            className="flex w-full flex-row items-center justify-center bg-white/[0.25] font-poppins text-xs text-blue-500"
            id="chat-fetching"
          >
            Fetching <div className="loader"></div>
          </div>
        )}

        <div
          className="flex h-[320px] max-h-[320px] flex-col px-1"
          id="chat-body"
        >
          <PerfectScrollbar
            className="flex h-[320px] max-h-[320px] flex-col px-1"
            containerRef={(el) => (ps.current = el)}
          >
            <ChatComponent messageList={messages} sender={sender} />
            {isTyping && (
              <div className="incoming flex h-[10px] w-[50px] flex-row items-center justify-center space-x-2 rounded-lg rounded-bl-none bg-white px-2 py-3">
                <div className="h-2 w-2 animate-jump-fade-dot rounded-full bg-gray-600"></div>
                <div className="h-2 w-2 animate-jump-fade-dot rounded-full bg-gray-600 delay-500"></div>
                <div className="h-2 w-2 animate-jump-fade-dot rounded-full bg-gray-600 delay-300"></div>
              </div>
            )}
          </PerfectScrollbar>
        </div>

        <div
          className="flex items-center justify-between rounded-b-2xl bg-white p-2 dark:bg-gray-700"
          id="chat-footer"
        >
          <input
            id="name-input"
            className="m-1 ml-2 h-12 w-full rounded-md border-none bg-white p-1 px-2 outline-none dark:bg-gray-800 dark:text-white"
            placeholder="Type a message"
            onKeyDown={handleKeyPress}
            onBlur={handleInputMessage}
            type="text"
            name="message"
          />

          <button
            className="delay-10 mx-1 flex cursor-pointer items-center justify-center rounded-full border-none bg-blue-500 p-2 text-lg text-white outline-none transition-all ease-in hover:shadow-md"
            onClick={() => handleSendMessage(message)}
          >
            <Send className="text-inherit" />
          </button>
        </div>
      </div>
    </Draggable>
  );
};

export default ChatWindow;
