
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    root: {
      display: "flex",
      flexDirection: "row",
    },
    TopBar: {
      display: "flex",
  
      // background: "#fff",
      borderRadius: "0px 0px 7px 7px",
      marginLeft: 18,
      marginRight: 16,
      boxShadow: "0px 0px 5px 0px rgba(191,191,191,0.75)",
      webkitBoxShadow: "0px 0px 5px 0px rgba(191,191,191,0.75)",
      mozBoxShadow: "0px 0px 5px 0px rgba(191,191,191,0.75)",
      // justifyContent: "space-between",
      alignItems: "center",
      minHeight: "10vh",
      margin: "10px",
    },
    burgerMenu: {
      margin: 10,
      color: "#212121",
    },
    iconContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      justifyItems: "flex-end",
    },
    mailNotificationIcon: {
      "&:hover": {
        color: "#03A9F4",
      },
    },
    cancelButton: {
      backgroundColor: "#fff",
      color: "#424242",
      "&:hover": {
        color: "#03A9F4",
      },
    },
    notificationIcon: {
      "&:hover": {
        color: "#ffc400",
      },
    },
    mailBadge: {
      "& .MuiBadge-badge": {
        backgroundColor: "#03A9F4",
        color: "#fff",
      },
    },
    notificationBadge: {
      "& .MuiBadge-badge": {
        backgroundColor: "#ffc400",
        color: "#fff",
      },
    },
    menuStyle: {
      display: "flex",
      flexDirection: "row",
      width: "350px",
      marginTop: 20,
    },
    notificationWrapper: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#fff",
      borderRadius: "8px",
      paddingRight: "px",
      paddingLeft: "15px",
      padding: "15px",
      width: "400px",
      maxHeight: "450px",
    },
    notificationHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "14px",
      color: "#616161",
      padding: "10px",
    },
    notificationTitle: {
      fontSize: "22px",
      paddingLeft: "10px",
      paddingTop: "5px",
    },
    notificationFooter: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "14px",
      color: "#9e9e9e",
      paddingLeft: "10px",
    },
    showAllButton: {
      color: "#007bff",
      textDecoration: "underline",
      cursor: "pointer",
    },
    markAllRead: {
      color: "#007bff",
      textDecoration: "underline",
      cursor: "pointer",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "10px",
      alignSelf: "flex-end",
    },
    notificationBody: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      paddingRight: "18px",
      paddingLeft: "10px",
      borderRadius: "8px",
      alignItems: "center",
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
    },
    notificationContent: {
      display: "flex",
      flexDirection: "column",
      paddingTop: "10px",
      paddingBottom: "5px",
      fontSize: "15px",
    },
    notificationTime: {
      display: "flex",
      flexDirection: "row",
      alignSelf: "flex-end",
      fontSize: "12px",
      color: "#9e9e9e",
    },
    notificationAvatar: {
      padding: "8px",
    },
    notifRegisterIcon: {
      backgroundColor: "#039be5",
      borderRadius: "50%",
      padding: "2px",
    },
    notifRejectIcon: {
      backgroundColor: "#e53935",
      borderRadius: "50%",
      padding: "2px",
    },
    notifCompletedIcon: {
      backgroundColor: "#00bfa5",
      borderRadius: "50%",
      padding: "2px",
    },
    notifMailIcon: {
      backgroundColor: "#ef6c00",
      borderRadius: "50%",
      padding: "2px",
    },
    shareContainer: {
      display: "flex",
      flexWrap: "wrap",
      flex: 1,
      flexDirection: "row",
      margin: 10,
    },
    modal: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    paper2: {
      backgroundColor: "#ffffff",
      borderRadius: "7px",
      padding: "5px 10px",
    },
    modalHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 10,
      padding: 5,
    },
    sceneLinkContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: 10,
      margin: 5,
      marginRight: 200,
      backgroundColor: "rgba(0, 0, 0, 0.06)",
      borderRadius: 5,
    },
    copyBtn: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      color: "#bdbdbd",
      "&:hover": {
        color: "#4fc3f7",
      },
    },
    expandSection: {
      display: "flex",
      alignItems: "center",
    },
    stepStyle: {
      display: "flex",
      flexDirection: "row",
      textAlign: "justify",
    },
    companyModal: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    headingWrapper: {
      display: "flex",
      flexDirection: "row",
      padding: 10,
      marginLeft: 50,
    },
    heading: {
      fontSize: 16,
      color: "#808080",
    },
  
    chipContainer: {
      display: "flex",
      minWidth: "540px",
      alignItems: "flex-start",
      "& > *": {
        margin: "4px",
      },
    },
    editorWrapper: {
      margin: 5,
      marginBottom: 10,
      display: "inline-block",
      minWidth: "500px",
      padding: 10,
    },
    editorContainer: {
      // marginLeft: 10,
      // marginTop: 20,
      // width: "95%",
      maxWidth: "95%",
      // minHeight: "200px",
      // maxHeight: "200px",
      // overflowY: "scroll",
      padding: "1rem",
      backgroundColor: "#fafafa",
      borderRadius: 15,
      boxShadow:
        "0.3px 0.3px 0.4px rgba(0, 0, 0, 0.003), 0.7px 0.7px 1.1px rgba(0, 0, 0, 0.004),1.3px 1.3px 2px rgba(0, 0, 0, 0.005),2.2px 2.2px 3.6px rgba(0, 0, 0, 0.006),4.2px 4.2px 6.7px rgba(0, 0, 0, 0.007),10px 10px 16px rgba(0, 0, 0, 0.01)",
    },
    editorToolbar: {
      backgroundColor: "#f5f5f5",
      border: "none",
      marginBottom: 0,
      boxShadow:
        "0 0 #000, 0 0 #0000, 0 0 #000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "& .rdw-option-wrapper": {
        height: 30,
        padding: 5,
        borderRadius: 5,
        border: "none",
        "&:hover": {
          transform: "scale(1.1, 1.1)",
          boxShadow:
            "0.3px 0.3px 0.4px rgba(0, 0, 0, 0.003), 0.7px 0.7px 1.1px rgba(0, 0, 0, 0.004),1.3px 1.3px 2px rgba(0, 0, 0, 0.005),2.2px 2.2px 3.6px rgba(0, 0, 0, 0.006),4.2px 4.2px 6.7px rgba(0, 0, 0, 0.007),10px 10px 16px rgba(0, 0, 0, 0.01)",
        },
      },
      "& .rdw-dropdown-wrapper": {
        height: 30,
        borderRadius: 5,
        "&:hover": {
          boxShadow:
            "0.3px 0.3px 0.4px rgba(0, 0, 0, 0.003), 0.7px 0.7px 1.1px rgba(0, 0, 0, 0.004),1.3px 1.3px 2px rgba(0, 0, 0, 0.005),2.2px 2.2px 3.6px rgba(0, 0, 0, 0.006),4.2px 4.2px 6.7px rgba(0, 0, 0, 0.007),10px 10px 16px rgba(0, 0, 0, 0.01)",
        },
      },
      "& .rdw-colorpicker-modal": {
        height: 250,
        width: 250,
        "& .rdw-colorpicker-modal-options": {
          overflow: "hidden !important",
          "& .rdw-colorpicker-option": {
            boxShadow: "none",
            padding: 0,
            "&:hover": {
              transform: "scale(1.3, 1.3)",
            },
          },
        },
      },
    },
    editor: {
      backgroundColor: "",
      padding: "1rem",
      marginBottom: 5,
      border: "none",
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      boxShadow:
        "0 0 #000, 0 0 #0000, 0 0 #000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    },
    editButton: {
      color: "#007BFF",
      textTransform: "none !important",
    },
    profilePic: {
      position: "relative",
      width: "80px",
      height: "80px",
      borderRadius: "50%",
      overflow: "hidden",
    },
    profilepic__text: {
      fontSize: "0.8rem",
    },
    saveButton: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      marginTop: 20,
      width: "96%",
    },
    title: {
      fontSize: 50,
      color: "#007BFF",
      padding: 12,
      fontWeight: "900",
      fontFamily: "Poppins",
    },
    fieldset: {
      padding: 5,
      maxWidth: "550PX",
      paddingBottom: 20,
      justifyContent: "flex-start",
      margin: 5,
      borderColor: "#eee",
      borderWidth: 1.5,
      borderStyle: "solid",
      borderRadius: 5,
      "& legend": {
        color: "#bdbdbd",
      },
      "&:hover": {
        borderColor: "#2A8BF2",
        "& legend": {
          color: "#2A8BF2",
        },
      },
    },
  }));
  