import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import LocalPostOfficeOutlinedIcon from "@mui/icons-material/LocalPostOfficeOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@material-ui/core/Chip";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import TourIcon from "@mui/icons-material/Tour";
import {
  addNotification,
  updateNotificationList,
  updateNotificationPath,
  setNotificationCount,
  removeNotificationPath,
} from "redux/dashboard/reducers/notificationSlice"; // TODO: remove
import {
  addCandidateNotification,
  updateCandidateNotificationList,
} from "redux/dashboard/reducers/CandidateNotificationSlice";
import {
  updateEmailNotificationList,
  addEmailNotification,
  updateEmailNotificationCount,
  setEmailNotificationCount,
} from "redux/dashboard/reducers/emailNotificationSlice"; // TODO: remove

import TourOutlinedIcon from "@mui/icons-material/TourOutlined";
import {
  Avatar,
  Menu,
  MenuItem,
  Button,
  IconButton,
  Typography,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Popover,
  Badge,
} from "@mui/material";
import "assets/dashboard/css/sidebar.css";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

import LogoutIcon from "@mui/icons-material/Logout";

import CloseIcon from "@mui/icons-material/Close";
import PeopleIcon from "@mui/icons-material/People";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import MenuIcon from "@mui/icons-material/Menu";
import NotificationList from "./Notification/NotificationList";
import CandidateNotificationList from "./CandidateNotification/NotificationList";

import CandidateEmailList from "./CandidateNotification/EmailNotificationList";
import EmailNotificationList from "./Notification/EmailNotificationList";
import SocialShare from "./SocialShare";

import {
  updateSidebarConfiguration,
  updateSupportWindowState,
} from "redux/dashboard/reducers/configSlice";

import { primaryFilledButton, primaryIconButton } from "theme/elements";
import { getCompanyDetails, updateCompanyDetails } from "api/dashboard/company";
import { uploadPrivateNoteAttachments } from "api/dashboard/candidate";

import { filterAssistant, getCompanyList } from "api/dashboard/assistant";

import { findByEmail, publishUser, FindUser } from "api/users";

import SnackBarComponent from "components/Snackbar";
import { getUserData, getUserRole } from "api/dashboard/permissions";

import { SERVER_URL, defaultProfileImage } from "constants/production";

// socket
import {
  initiateSocket,
  subscribeToEvent,
  sendEvent,
  initiateNotificationSocket,
  disconnectSocket,
} from "utils/socket.io";

import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { updateFirstLoginData } from "api/auth";

import SupportWidget from "components/Support";

import { ContentState, convertToRaw, EditorState } from "draft-js";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import {
  addUser,
  updateFirstLoginStatus,
} from "redux/dashboard/reducers/userSlice";

import { useStyles } from "./TopBarStyle";

import { getResumeData } from "api/dashboard/parseResume";
import {
  ChevronRightRounded,
  Diversity1Outlined,
  HelpOutlineRounded,
} from "@mui/icons-material";

import { industryList } from "constants/industries";
import NotificationPopup from "./Notification/NotificationPopup";

export default function TopBar() {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const routerHistory = useHistory();
  const socketRef = useRef();
  const notificationSocketRef = useRef();
  // const navigate = useNavigate();
  const location = useLocation();
  const baseUrl = window.location.protocol + "//" + window.location.host;

  const [companyData, setCompanyData] = useState();

  const { pathname } = useLocation();
  const history = useHistory();

  const supportChatWindowOpen = useAppSelector(
    (state) => state?.configReducer?.config?.supportChatWindow?.open,
  );

  const company = useAppSelector(
    (state) => state?.userReducer?.user?.company?._id,
  );
  const userId = useAppSelector(
    (state) => state?.userReducer?.user?.loggedUser?.userKey,
  );

  const userData = useAppSelector((state) => state?.userReducer?.user);

  const _userData = useAppSelector(
    (state) => state?.userReducer?.user?.loggedUser,
  );

  const [resumeImage, setResumeImage] = useState();

  useEffect(() => {
    if (userId) {
      getResumeData(userId)
        .then((response) => {
          setResumeImage(response?.data?.profile_image);
        })
        .catch((error) => {
          console.error("error", error);
        });
    }

    return () => {};
  }, [userId]);

  const notificationCount = useAppSelector(
    (state) => state?.notificationReducer?.notificationCount,
  );

  const emailNotificationCount = useAppSelector(
    (state) => state?.emailNotificationReducer?.emailNotificationCount,
  );

  const isExpanded = useAppSelector(
    (state) => state?.configReducer?.config?.sidebar?.isExpanded,
  );

  const candidateId = useAppSelector(
    (state) => state?.userReducer?.user?.loggedUser?.userId,
  );

  console.log("candidateId", candidateId);
  const userEmail = useAppSelector(
    (state) => state?.userReducer?.user?.loggedUser?.email,
  );

  const admin_id = useAppSelector((state) => state.userReducer.user.id);
  const loginStatus = useAppSelector(
    (state) => state.userReducer.user.isFirstLogin,
  );

  // notification
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationBody, setNotificationBody] = useState(<></>);
  const [notificationIcon, setNotificationIcon] = useState("");
  const [notificationLink, setNotificationLink] = useState("");

  const [adminId, setAdminId] = useState(admin_id);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifAnchorEl, setNotifAnchorEl] = React.useState(null);
  const [publish, setPublish] = React.useState(false);
  const [mailBadgeCount, setMailBadgeCount] = useState(emailNotificationCount);
  const [notificationBadgeCount, setNotificationBadgeCount] =
    useState(notificationCount);
  const [isCopied, setIsCopied] = useState(false);
  const [shareUrl, setShareUrl] = useState();

  const [candidateMailBadgeCount, setCandidateMailBadgeCount] = useState(0);
  const [candidateNotificationBadgeCount, setCandidateNotificationBadgeCount] =
    useState(0);

  const [companyModalOpen, setCompanyModalOpen] = useState(false);

  const [about, setAbout] = useState();
  const [mission, setMission] = useState();
  const [vision, setVision] = useState();
  const [employees, setEmployees] = useState(null);
  const [industry, setIndustry] = useState();
  const [profileVideoTitle, setProfileVideoTitle] = useState();
  const [profileVideoUrl, setProfileVideoUrl] = useState();
  const [profile, setProfile] = useState();
  const [github, setGithub] = useState();
  const [linkedIn, setLinkedIn] = useState();
  const [twitter, setTwitter] = useState();
  const [facebook, setFacebook] = useState();
  const [instagram, setInstagram] = useState();
  const [customerId, setCustomerId] = useState();

  const [_name, setName] = useState();
  const [_instructions, setInstructions] = useState();
  const [assistantId, setAssistantId] = useState();

  // snack bar
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarStatus, setSnackbarStatus] = useState(0);

  const hideSnackbar = () => {
    setTimeout(() => {
      setIsSnackbarOpen(false);
      setSnackbarMessage("");
      setSnackbarStatus(0);
    }, 5000);
  };

  const updateSnackbar = (message, status, autoHide = false) => {
    setIsSnackbarOpen(true);
    setSnackbarMessage(message);
    setSnackbarStatus(status);
    autoHide && hideSnackbar();
  };

  useEffect(() => {
    setMailBadgeCount(emailNotificationCount);

    return () => {};
  }, [emailNotificationCount, notificationCount]);

  useEffect(() => {
    fetchUserData();
    return () => {};
  }, []);

  const [editorStateAbout, setEditorStateAbout] = useState();
  const [editorStateMission, setEditorStateMission] = useState();
  const [editorStateVision, setEditorStateVision] = useState();

  const fetchUserData = () => {
    findByEmail(userEmail)
      .then((response) => {
        setPublishStatus(response?.data?.published);
        setCustomerId(response?.data?.subscription?.customerId);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (company) {
      getCompanyDetails(company)
        .then((result) => {
          setCompanyData(result[0]?.companyDetails);
          if (result[0]?.about) {
            const blocksFromHtml = htmlToDraft(result[0]?.about);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(
              contentBlocks,
              entityMap,
            );
            const newEditorState = EditorState.createWithContent(contentState);
            setEditorStateAbout(newEditorState);
          }
          if (result[0]?.mission) {
            const blocksFromHtml = htmlToDraft(result[0]?.about);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(
              contentBlocks,
              entityMap,
            );
            const newEditorState = EditorState.createWithContent(contentState);
            setEditorStateMission(newEditorState);
          }
          if (result[0]?.vision) {
            const blocksFromHtml = htmlToDraft(result[0]?.about);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(
              contentBlocks,
              entityMap,
            );
            const newEditorState = EditorState.createWithContent(contentState);
            setEditorStateVision(newEditorState);
          }
          setProfile(result[0]?.profileImage);
          setEmployees(result[0]?.no_of_employees);
          setIndustry(result[0]?.industry);
        })
        .catch((error) => {
          console.error("failed to fetch company data : ", error);
        });
    }
    return () => {};
  }, [company]);

  const notificationClick = (event) => {
    setNotifAnchorEl(event.currentTarget);
    setNotificationBadgeCount(0);
    setCandidateNotificationBadgeCount(0);
  };

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const notifClose = () => {
    setNotifAnchorEl(null);
  };

  const notifOpen = Boolean(notifAnchorEl);
  const popoverId = notifOpen ? "simple-popover" : undefined;

  const [mailNotifAnchorEl, setMailNotifAnchorEl] = React.useState(null);

  const mailNotificationClick = (event) => {
    setMailNotifAnchorEl(event.currentTarget);
    setMailBadgeCount(0);
    setCandidateMailBadgeCount(0);
  };

  const mailNotifClose = () => {
    setMailNotifAnchorEl(null);
  };

  const mailNotifOpen = Boolean(mailNotifAnchorEl);
  const mailPopoverId = mailNotifOpen ? "simple-popover" : undefined;

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const profilePage = () => {
    if (_userData?.user === "user") {
      routerHistory.push("/dashboard/settings/profile");
    } else {
      routerHistory.push("/candidate/settings");
    }
    handleClose();
  };

  const logout = () => {
    dispatch(addUser());
    localStorage.clear();
    routerHistory.push("/");

    // if (_userData?.user === "user") {
    //   dispatch(userPermission());
    //   dispatch(departmentPermission());
    //   dispatch(sceneSetupPermission());
    //   dispatch(departmentCreatePermission());
    // }
    // dispatch(addUser());
    // dispatch(loggedUser());
    // dispatch(updateAuthToken(""));
  };

  const upgradePlan = () => {
    routerHistory.push("/dashboard/upgrade");
    const form = document.getElementById("subscriptionForm");
    const customerInput = document.getElementById("customerInput");
    if (customerInput) {
      customerInput.value = customerId;
    }
    if (form) {
      form.submit(); // Submit the form
    }
  };

  const queryParams = new URLSearchParams(location.search);
  let publishValue = queryParams.get("publish");

  useEffect(() => {
    if (publishValue) {
      handleOpenPublish();
    }

    return () => {};
  }, [publishValue, shareUrl]);

  const handleOpenPublish = () => {
    if (_userData?.user === "user") {
      setShareUrl(`${baseUrl}/company_space/start/${company}?refId=${userId}`);
    } else {
      setShareUrl(`${baseUrl}/candidate_space/${candidateId}/start`);
    }
    setPublish(true);
    // setPublish(_data);
  };
  const handleClosePublish = () => {
    setPublish(false);
  };

  const [publishStatus, setPublishStatus] = useState();

  const handlePublish = () => {
    if (userId) {
      const data = {
        published: true,
      };
      publishUser(userId, data)
        .then((res) => {
          updateSnackbar("Scene published successfully", 1, 1);
          fetchUserData();
        })
        .catch((error) => {
          updateSnackbar("Failed to publish scene", 2, 1);
          console.error("Failed to publish, Error: ", error);
        });
    }
  };

  const handleUnpublishClick = () => {
    if (userId) {
      const data = {
        published: false,
      };
      publishUser(userId, data)
        .then((res) => {
          updateSnackbar("Scene unpublished successfully", 1, 1);
          fetchUserData();
        })
        .catch((error) => {
          updateSnackbar("Failed to unpublish scene", 2, 1);
          console.error("Failed to unpublish, Error: ", error);
        });
    }
  };

  const openCompanyChat = () => {
    history.push("/dashboard/chat/inbox?tab=1");
  };

  const [showNotification, setShowNotification] = useState(false);

  const handleConnectionNotification = (data) => {
    setShowNotification(true);
    setNotificationTitle("You've A New Connection Request");
    setNotificationBody(
      <div className="text-wrap font-poppins text-xs">
        You have new connection request from {data?.requester?.companyName},
        referred you {data?.jobData?.job_name}
      </div>,
    );
    setNotificationLink(
      `${process.env.REACT_APP_WEB_CLIENT}/company_space/share/company/${data?.jobData?.company}/department/${data?.jobData?.department}/job/${data?.jobData?._id}?refId=${data?.requesterId}`,
    );

    setNotificationIcon(data?.requester?.companyProfile);
  };

  const setupEventListeners = (socket) => {
    subscribeToEvent(socket, "candidate-email", (response) => {
      if (response?.email_type === "internal") {
        setCandidateNotificationBadgeCount((prevValue) => prevValue + 1);
      } else if (response?.email_type === "external") {
        setCandidateMailBadgeCount((prevValue) => prevValue + 1);
      }
    });

    subscribeToEvent(socket, "connection_request", (response) => {
      // process connection request
      console.log("event: connection_message: ", response);
      handleConnectionNotification(response?.data);
    });
  };

  // handle notification socket events
  const setUpNotificationListeners = (socket) => {
    subscribeToEvent(socket, "notification_joined", (response) => {
      console.log("socket notification payload", response);
    });

    subscribeToEvent(socket, "outgoing_notification", (response) => {
      console.log("outgoing_notification: ", response);
      if (response?.body?.data?.type === "data") {
        console.log("dataaaa");
        dispatch(addNotification(response));
      } else {
        console.log("emaillll");
        dispatch(addEmailNotification(response));
      }
    });
    if (candidateId) {
      console.log("subscribing to candidate notification");
      subscribeToEvent(
        socket,
        "outgoing_candidate_notification",
        (response) => {
          console.log("outgoing_candidate_notification: ", response);
          if (response?.body?.data?.type === "data") {
            console.log("dataaaa");
            dispatch(addCandidateNotification(response));
          } else {
            console.log("emaillll");
            dispatch(addEmailNotification(response));
          }
        },
      );
    }
  };

  useEffect(() => {
    const socket = initiateSocket(SERVER_URL, userData?.token, userId);
    socketRef.current = socket;

    // Wait for the socket to connect before subscribing to events
    if (socket.connected) {
      // If already connected, subscribe immediately
      setupEventListeners(socket);
    } else {
      // Otherwise, wait for the 'connect' event
      socket.on("connect", () => {
        setupEventListeners(socket);
      });
    }

    return () => {
      socketRef.current && disconnectSocket(socketRef.current);
    };
  }, [userData?.token]);

  useEffect(() => {
    if (userData?.token && userId) {
      const rooms = [`notification_${userId}`];
      if (company) {
        rooms.push(`notification_${company}`);
      }
      if (candidateId) {
        rooms.push(`notification_${candidateId}`);
      }
      console.log("rooms", rooms);
      const notificationSocket = initiateNotificationSocket(
        SERVER_URL,
        userData?.token,
        rooms,
      );
      notificationSocketRef.current = notificationSocket;

      // Wait for the socket to connect before subscribing to events
      if (notificationSocket.connected) {
        // If already connected, subscribe immediately
        setUpNotificationListeners(notificationSocket);
      } else {
        // Otherwise, wait for the 'connect' event
        notificationSocket.on("connect", () => {
          setUpNotificationListeners(notificationSocket);
        });
      }
    }

    return () => {
      notificationSocketRef.current &&
        disconnectSocket(notificationSocketRef.current);
    };
  }, [userData?.token, userId, candidateId]);

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (!userData?.isFirstLogin) {
      handleDialogOpen();
    }
    return () => {};
  }, []);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    if (!userData?.isFirstLogin) {
      updateFirstTimeLogin();
    }
  };

  const [isJoyrideActive, setIsJoyrideActive] = useState(false);

  const handleStartButtonClick = () => {
    dispatch(updateSidebarConfiguration({ isExpanded: true }));
    setIsJoyrideActive(true);
    if (!userData?.isFirstLogin) {
      updateFirstTimeLogin();
    }
  };

  // useEffect(() => {
  //   if (loginStatus === true && isJoyrideActive === true)
  //     return () => {
  //       navigate("/candidate/settings");
  //     };
  // }, [loginStatus, isJoyrideActive]);

  const joyrideCompanySteps = [
    {
      title: "Insights",
      target: ".Insights",
      content: (
        <div className={classes.stepStyle}>
          <p
            style={{
              paddingRight: 10,
              fontFamily: "'Poppins', 'Inter', sans-serif !important",
              fontSize: 16,
            }}
          >
            Welcome to Insights! Here, you can view data-driven analyses of your
            hiring activities and job search. Monitor trends, keep an eye on key
            metrics, and gain valuable insights into your recruitment process.
            Click 'Next' to learn more!
          </p>
          <video controls>
            <source src="https://youtu.be/QFaFIcGhPoM" type="video/mp4" />
          </video>
        </div>
      ),
      disableBeacon: true,
    },
    {
      title: "Explore Space Setup",
      target: ".Spaces",
      content: (
        <div className={classes.stepStyle}>
          <p
            style={{
              paddingRight: 10,
              fontFamily: "'Poppins', 'Inter', sans-serif !important",
              fontSize: 16,
            }}
          >
            Dive into Spaces! This section lets you explore candidates' 3D
            Virtual Office Spaces. Find suitable candidates, and discover more
            about them by interacting with their AI avatars. Click 'Next' for an
            immersive experience!
          </p>
          <video controls>
            <source src="https://youtu.be/QFaFIcGhPoM" type="video/mp4" />
          </video>
        </div>
      ),
      disableBeacon: true,
    },
    {
      title: "Departments",
      target: ".Departments",
      content: (
        <div className={classes.stepStyle}>
          <p
            style={{
              paddingRight: 10,
              fontFamily: "'Poppins', 'Inter', sans-serif !important",
              fontSize: 16,
            }}
          >
            Welcome to 'Departments'! Organize your company's various
            departments and job openings here. Let our AI generate job
            descriptions and assessment questions, or manually enter details for
            full control. This section streamlines hiring, ensuring every
            department finds its perfect match!
          </p>
          <video controls>
            <source src="https://youtu.be/QFaFIcGhPoM" type="video/mp4" />
          </video>
        </div>
      ),
      disableBeacon: true,
    },
    {
      title: "Inbox",
      target: ".Candidates",
      content: (
        <div className={classes.stepStyle}>
          <p
            style={{
              paddingRight: 10,
              fontFamily: "'Poppins', 'Inter', sans-serif !important",
              fontSize: 16,
            }}
          >
            Your centralized hub for tracking applicants. Easily manage, review,
            and communicate with candidates for each job position. Stay
            organized and make informed decisions to find the perfect fit!
          </p>
          <video controls>
            <source src="https://youtu.be/QFaFIcGhPoM" type="video/mp4" />
          </video>
        </div>
      ),
      disableBeacon: true,
    },
    {
      title: "Space Setup",
      target: ".Scenesetup",
      content: (
        <div className={classes.stepStyle}>
          <p
            style={{
              paddingRight: 10,
              fontFamily: "'Poppins', 'Inter', sans-serif !important",
              fontSize: 16,
            }}
          >
            Customize your 3D space and create a virtual representation of your
            company and its values. From avatars to backdrops, make it truly
            yours. Click 'Next' to unleash your creativity!
          </p>
          <video controls>
            <source src="https://youtu.be/QFaFIcGhPoM" type="video/mp4" />
          </video>
        </div>
      ),
      disableBeacon: true,
    },
    {
      title: "Bots",
      target: ".company-bots",
      content: (
        <div className={classes.stepStyle}>
          <p
            style={{
              paddingRight: 10,
              fontFamily: "'Poppins', 'Inter', sans-serif !important",
              fontSize: 16,
            }}
          >
            Meet your AI Bots! Automate repetitive tasks, engage with visitors,
            or get instant help with queries. Set up bots according to your
            needs and let them enhance your Bellohire candidate experience.
            Click to get started!
          </p>
          <video controls>
            <source src="https://youtu.be/QFaFIcGhPoM" type="video/mp4" />
          </video>
        </div>
      ),
      disableBeacon: true,
    },
    {
      title: "User Interactions",
      target: ".company-user-interactions",
      content: (
        <div className={classes.stepStyle}>
          <p
            style={{
              paddingRight: 10,
              fontFamily: "'Poppins', 'Inter', sans-serif !important",
              fontSize: 16,
            }}
          >
            Navigate User Interactions! Understand how candidates engage with
            your spaces. Analyze behaviors and optimize for success. Click to
            dive into the details!
          </p>
          <video controls>
            <source src="https://youtu.be/QFaFIcGhPoM" type="video/mp4" />
          </video>
        </div>
      ),
      disableBeacon: true,
    },
    {
      title: "Integrations",
      target: ".company-integrations",
      content: (
        <div className={classes.stepStyle}>
          <p
            style={{
              paddingRight: 10,
              fontFamily: "'Poppins', 'Inter', sans-serif !important",
              fontSize: 16,
            }}
          >
            Welcome to Integrations! Connect BelloHire with your favorite tools
            and platforms. From emails to sms, streamline your processes and
            make everything work together seamlessly. Click 'Next' to explore
            possibilities!!
          </p>
          <video controls>
            <source src="https://youtu.be/QFaFIcGhPoM" type="video/mp4" />
          </video>
        </div>
      ),
      disableBeacon: true,
    },
    {
      title: "Other Settings",
      target: ".company-other-settings",
      content: (
        <div className={classes.stepStyle}>
          <p
            style={{
              paddingRight: 10,
              fontFamily: "'Poppins', 'Inter', sans-serif !important",
              fontSize: 16,
            }}
          >
            Explore Other Settings! Tailor your BelloHire experience with tags,
            templates, and more. Adjust everything to fit your unique needs.
            Click to personalize your journey!
          </p>
          <video controls>
            <source src="https://youtu.be/QFaFIcGhPoM" type="video/mp4" />
          </video>
        </div>
      ),
      disableBeacon: true,
    },
    {
      title: "Permission",
      target: ".company-permission",
      content: (
        <div className={classes.stepStyle}>
          <p
            style={{
              paddingRight: 10,
              fontFamily: "'Poppins', 'Inter', sans-serif !important",
              fontSize: 16,
            }}
          >
            Discover Permissions! Manage who can see and do what within your
            profile, spaces, and company setup. Control access and ensure
            privacy and collaboration are balanced. Click 'Next' to set up!
          </p>
          <video controls>
            <source src="https://youtu.be/QFaFIcGhPoM" type="video/mp4" />
          </video>
        </div>
      ),
      disableBeacon: true,
    },
    {
      title: "Profile",
      target: ".company-profile",
      content: (
        <div className={classes.stepStyle}>
          <p
            style={{
              paddingRight: 10,
              fontFamily: "'Poppins', 'Inter', sans-serif !important",
              fontSize: 16,
            }}
          >
            Welcome to your Profile! Edit and update your personal or company
            information. Your profile is your face on Bellohire, so make it
            shine. Click 'Next' to begin!
          </p>
          <video controls>
            <source src="https://youtu.be/QFaFIcGhPoM" type="video/mp4" />
          </video>
        </div>
      ),
      disableBeacon: true,
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type, step } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setIsJoyrideActive(false);
      handleDialogClose();

      if (index === joyrideSteps.length - 1) {
        // Redirect to a different page
        routerHistory.push("/candidate/settings?tab=2");
      }
    }
    if (step.target === ".Departments") {
      var element = document.getElementById("sidebar-scroll");
      element.scrollTop = element.scrollHeight - element.clientHeight;

      const ps = new PerfectScrollbar("#sidebar-scroll", {
        suppressScrollX: true,
      });
      document
        .querySelector("#sidebar-scroll")
        .addEventListener("ps-y-reach-end", () => {
          ps.update();
        });
    }
  };

  const joyrideSteps = [
    {
      title: "Spaces",
      target: ".Spaces",
      content:
        "Explore 3D Virtual Office Space on the Spaces Page! You can see companies and their jobes out there in Bellohire. Find suitable jobs, and get to know more about companies by interacting with their AI avatars in their spaces or apply for a job. Navigate around to see how it works or click 'Next' for more.",
      disableBeacon: true,
    },
    {
      title: "Screening",
      target: ".Screening",
      content:
        "Welcome to the Screening Page! Here, you'll be able to add a series of questions that help you get to know your recruiters or company better. Take your time and add questions to companies. Click 'Next' to continue.",
      disableBeacon: true,
    },
    {
      title: "Chat",
      target: ".Chats",
      content:
        "Connect  with recruiters and fellow candidates you met in their spaces in real-time on the Chats Page. Start a conversation, share insights, and build your network. Click on any chat to join, or 'Next' to proceed.",
      disableBeacon: true,
    },
    {
      title: "Space Setup",
      target: ".Spacesetup",
      content:
        "Welcome to the Space Setup Page! This is where you create your unique 3D space and profile that represents you. Use the tools to design and preview your space. Click 'Next' when you're ready to move on",
      disableBeacon: true,
    },
    // {
    //   target: ".Settings",
    //   content: "candidate settings data",
    // },
    {
      title: "Setup AI",
      target: ".setup-ai-data",
      content:
        "Time to give your brain to your AI Avatar! On the Setup AI Page, you'll train your virtual self to interact with others. It's a fun and engaging way to present yourself. Click 'Next' to see what's next",
      disableBeacon: true,
    },
  ];

  const customCompanyStyles = {
    options: {
      primaryColor: "#007bff",
      width: 700,
    },
  };

  const updateFirstTimeLogin = () => {
    updateFirstLoginData(userId, { fistLogin: true })
      .then((_res) => {
        dispatch(updateFirstLoginStatus(true));
        if (_userData?.role === "Admin" || _userData?.role === "user") {
          // setCompanyModalOpen(true);
        }
      })
      .catch((error) => {
        console.error("failed to update first login updated : ", error);
      });
  };

  const handleStartTour = () => {
    setDialogOpen(false);
    setIsJoyrideActive(true);
  };

  const handleCloseCompanyModal = () => {
    setCompanyModalOpen(false);
  };

  const handleAttachment = (e) => {
    const images = {
      imagePath: URL.createObjectURL(e.target.files[0]),
      fileName: e.target.files[0].name,
    };
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    if (formData) {
      uploadPrivateNoteAttachments(formData)
        .then((result) => {
          setProfile(result?.data[0]?.location);
          // dispatch(updateProfileImage(result?.data[0]?.location));
          dispatch(updateProfilePhoto(result?.data[0]?.location));
          const data = { profileImage: result?.data[0]?.location };

          updateCompanyDetails(company, { data })
            .then((_result) => {
              setSnackbarMessage("Profile image changed successfully");
              setIsSnackbarOpen(true);
              setSnackbarStatus(1);
              setTimeout(() => {
                setIsSnackbarOpen(0);
                setSnackbarStatus(0);
                setSnackbarMessage("");
              }, 3000);
              getCompanyDetails(company)
                .then((data) => {
                  setProfile(data?.data[0]?.profileImage);
                })
                .catch((error) => {
                  console.error("failed to get error : ", error);
                });
            })
            .catch((error) => {
              updateSnackbar("Failed to change profile image", 2, true);
            });
        })
        .catch((error) => {
          console.error("failed to upload image", error);
        });
    }
  };

  const fetchAssistantData = () => {
    if (company) {
      getCompanyList(company)
        .then((res) => {
          console.log("company data", res);
          setName(res[0]?.name);
          setInstructions(res[0]?.instructions);
          setAssistantId(res[0]?.id);
        })
        .catch((error) => {
          console.error("failed to get company assistant ", error);
        });
    }
  };

  useEffect(() => {
    if (company) {
      fetchAssistantData();
    }

    return () => {};
  }, [company]);

  const handleSaveCompanyData = () => {
    const data = {
      industry: industry,
      no_of_employees: employees,
      about: about,
      mission: mission,
      vision: vision,
      profileImage: profile,
      assistantId: assistantId,
      _name: _name,
      _instruction: _instructions,
      profile_video: {
        title: profileVideoTitle,
        url: profileVideoUrl,
      },
      social_media: {
        gitHub: github,
        linkedIn: linkedIn,
        facebook: facebook,
        instagram: instagram,
        twitter: twitter,
      },
    };
    updateCompanyDetails(company, data)
      .then((res) => {
        setSnackbarMessage("Profile Updated Successfully");
        setIsSnackbarOpen(true);
        setSnackbarStatus(1);
        setTimeout(() => {
          setIsSnackbarOpen(0);
          setSnackbarStatus(0);
          setSnackbarMessage("");
        }, 3000);
        getCompanyDetails(company)
          .then((result) => {
            setCompanyData(result[0]?.companyDetails);
            setAbout(result[0]?.about);
            setMission(result[0]?.mission);
            setVision(result[0]?.vision);
            setProfile(result[0]?.profileImage);
            setEmployees(result[0]?.no_of_employees);
            setIndustry(result[0]?.industry);
            setCompanyModalOpen(false);
          })
          .catch((error) => {
            console.error("failed to fetch comany data : ", error);
          });
      })
      .catch((error) => {});
  };

  const handleIndustry = (event) => {
    setIndustry(event.target.value);
  };

  const handleEmployeeClick = (label) => {
    setEmployees(employees === label ? null : label);
  };

  const convertHtmlToPlainText = (htmlText) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlText, "text/html");
    return doc.body.textContent;
  };

  const [aboutEditorState, setAboutEditorState] = useState();
  const [aboutRemainingCharacters, setAboutRemainingCharacters] =
    useState(2600);

  const handleAboutChange = (newEditorState) => {
    if (setAboutRemainingCharacters > 0) {
      setAboutEditorState(newEditorState);
      const content = newEditorState.getCurrentContent();
      const plainText = content.getPlainText("");
      const currentCharacters = plainText.length;
      setAboutRemainingCharacters(2600 - currentCharacters);

      const rawContentState = convertToRaw(content);
      const html = draftToHtml(rawContentState);
      setAbout(html);
    }
  };

  const [missionEditorState, setMissionEditorState] = useState();
  const [missionRemainingCharacters, setMissionRemainingCharacters] =
    useState(2600);

  const handleMissionChange = (newEditorState) => {
    if (missionRemainingCharacters > 0) {
      setMissionEditorState(newEditorState);
      const content = newEditorState.getCurrentContent();
      const plainText = content.getPlainText("");
      const currentCharacters = plainText.length;
      setMissionRemainingCharacters(2600 - currentCharacters);

      const rawContentState = convertToRaw(content);
      const html = draftToHtml(rawContentState);

      setMission(html);
    }
  };

  const [visionEditorState, setVisionEditorState] = useState();
  const [visionRemainingCharacters, setVisionRemainingCharacters] =
    useState(2600);

  const handleVisionChange = (newEditorState) => {
    if (visionRemainingCharacters > 0) {
      setVisionEditorState(newEditorState);
      const content = newEditorState.getCurrentContent();
      const plainText = content.getPlainText("");
      const currentCharacters = plainText.length;
      setVisionRemainingCharacters(2600 - currentCharacters);

      const rawContentState = convertToRaw(content);
      const html = draftToHtml(rawContentState);

      setVision(html);
    }
  };

  const handleSidebarCollapse = () => {
    dispatch(updateSidebarConfiguration({ isExpanded: !isExpanded }));
  };

  const handleSupportBtnClick = () => {
    dispatch(updateSupportWindowState(!supportChatWindowOpen));
  };

  return (
    <div className={classes.root}>
      <NotificationPopup
        show={showNotification}
        title={notificationTitle}
        content={notificationBody}
        link={notificationLink}
        duration={20 * 1000}
        icon={notificationIcon || "https://via.placeholder.com/150"}
        onClose={() => setShowNotification(false)}
      />
      <Joyride
        steps={
          _userData?.role === "candidate" ? joyrideSteps : joyrideCompanySteps
        }
        scrollToFirstStep
        // scrollToSteps={true}
        run={isJoyrideActive}
        continuous
        showProgress
        callback={handleJoyrideCallback}
        showSkipButton={true}
        styles={customCompanyStyles}
      />
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DialogTitle>Welcome to Bellohire!</DialogTitle>
          <DialogContent>
            {_userData?.role === "candidate" ? (
              <DialogContentText
                style={{ textAlign: "justify", alignItems: "justify" }}
              >
                As a first-time user, we've designed an exciting virtual tour to
                guide you through our unique platform. From creating 3D spaces
                to interacting with AI avatars, you'll discover innovative ways
                to showcase your skills and connect with recruiters. Ready to
                take the next step in your career journey? Let's begin the tour!
              </DialogContentText>
            ) : (
              <DialogContentText
                style={{ textAlign: "justify", alignItems: "justify" }}
              >
                Welcome to Bellohire's Company Dashboard! As a first-time
                recruiter or business user, we've prepared an interactive
                virtual tour to guide you through our revolutionary hiring
                platform. From managing 3D company spaces to insightful
                analytics and AI-powered bots, you'll explore state-of-the-art
                tools designed to streamline your recruitment process. Ready to
                transform the way you hire? Let's embark on the tour!
              </DialogContentText>
            )}
          </DialogContent>
        </div>

        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button
            onClick={handleStartTour}
            sx={primaryFilledButton}
            color="primary"
          >
            Start
          </Button>
        </DialogActions>
      </Dialog>
      <SnackBarComponent
        open={isSnackbarOpen}
        message={snackbarMessage}
        size={16}
        status={snackbarStatus}
        style={{ backgroundColor: "#fff" }}
      />
      <div
        className="flex items-center transition-all delay-200 ease-linear"
        style={{
          marginLeft: isExpanded ? "260px" : "70px",
        }}
      >
        {!pathname.includes("/dashboard/scene-setup") && (
          <button
            className="ml-1 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-none bg-transparent shadow-sm outline-none hover:text-blue-500 hover:shadow-md"
            onClick={handleSidebarCollapse}
          >
            {isExpanded ? (
              <MenuIcon className="text-inherit" />
            ) : (
              <ChevronRightRounded className="text-inherit" />
            )}
          </button>
        )}
      </div>

      {history.location.pathname === "/dashboard/scene-setup" ? (
        <div
          style={{
            display: "none",
            flexDirection: "row",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
            marginLeft: 5,
            gap: 3,
            marginRight: 5,
            marginTop: 35,
          }}
        >
          <IconButton
            data-testid="show-mails"
            aria-label="show 4 new mails"
            style={{ color: "#353935" }}
            onClick={mailNotificationClick}
          >
            <Badge badgeContent={mailBadgeCount} className={classes.mailBadge}>
              <LocalPostOfficeOutlinedIcon
                className={classes.mailNotificationIcon}
              />
            </Badge>
          </IconButton>
          <IconButton
            data-testid="candidate-notification-list"
            aria-label="show 17 new notifications"
            style={{ color: "#353935" }}
            onClick={notificationClick}
          >
            <Badge
              badgeContent={notificationBadgeCount}
              className={classes.notificationBadge}
            >
              <NotificationsNoneOutlinedIcon
                className={classes.notificationIcon}
              />
            </Badge>
          </IconButton>

          {_userData?.role === "Admin" || _userData?.role === "user" ? (
            <>
              <NotificationList
                popoverId={popoverId}
                notifAnchorEl={notifAnchorEl}
                notifOpen={notifOpen}
                notifClose={notifClose}
              />
              <EmailNotificationList
                popoverId={mailPopoverId}
                notifAnchorEl={mailNotifAnchorEl}
                notifOpen={mailNotifOpen}
                notifClose={mailNotifClose}
              />
            </>
          ) : null}

          {_userData?.role === "candidate" ? (
            <>
              <CandidateNotificationList
                popoverId={popoverId}
                notifAnchorEl={notifAnchorEl}
                notifOpen={notifOpen}
                notifClose={notifClose}
              />
              <CandidateEmailList
                popoverId={mailPopoverId}
                notifAnchorEl={mailNotifAnchorEl}
                notifOpen={mailNotifOpen}
                notifClose={mailNotifClose}
              />
            </>
          ) : (
            <>
              <NotificationList
                popoverId={popoverId}
                notifAnchorEl={notifAnchorEl}
                notifOpen={notifOpen}
                notifClose={notifClose}
              />
              <EmailNotificationList
                popoverId={mailPopoverId}
                notifAnchorEl={mailNotifAnchorEl}
                notifOpen={mailNotifOpen}
                notifClose={mailNotifClose}
              />
            </>
          )}

          <Typography component="div">{_userData?.username}</Typography>

          <>
            <Tooltip title="Account settings">
              <IconButton
                data-testid="acnt-settings-btn"
                onClick={handleClick}
                style={{ color: "#353935", borderRadius: 15 }}
              >
                <Avatar
                  id="avatar"
                  src={
                    _userData?.profile_photo
                      ? _userData?.profile_photo
                      : resumeImage
                  }
                  sx={{ width: 30, height: 30, bgcolor: "#007bff" }}
                >
                  {_userData?.username && _userData?.username.charAt(0)}
                </Avatar>
              </IconButton>
            </Tooltip>
          </>

          <Tooltip
            placement="bottom-start"
            title=" Click on the publish button and copy the link or you can share it in any of the social handles."
          >
            <button
              data-testid="publish-btn"
              sx={primaryFilledButton}
              onClick={handleOpenPublish}
            >
              Publish
            </button>
          </Tooltip>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
            marginLeft: 5,
            gap: 3,
            marginRight: 5,
          }}
          className="pt-1"
        >
          {/* support widget */}
          <div className=" cursor-pointer">
            <button
              className="pulse group m-1 flex h-10 w-10 flex-1 cursor-pointer items-center justify-center rounded-full border-none bg-transparent p-1 font-poppins text-lg text-black/[0.5] shadow-sm outline-none transition-all delay-200 ease-linear hover:w-auto hover:text-blue-500 hover:shadow-md"
              onClick={handleSupportBtnClick}
            >
              <HelpOutlineRounded className="text-inherit" />
              <span className="mx-2 ml-2 hidden text-sm group-hover:inline-block">
                Support
              </span>
            </button>
          </div>

          <Tooltip title="Bellohire Tour: How to get started">
            <IconButton
              className="bg-white hover:text-indigo-500 hover:shadow-md"
              onClick={handleStartButtonClick}
            >
              <Badge
                badgeContent={candidateMailBadgeCount}
                className={classes.mailBadge}
              >
                <TourOutlinedIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          {/*  mail count */}
          <IconButton
            className="bg-white hover:text-blue-500 hover:shadow-md"
            onClick={mailNotificationClick}
          >
            <Badge
              badgeContent={candidateMailBadgeCount}
              className={classes.mailBadge}
            >
              <LocalPostOfficeOutlinedIcon />
            </Badge>
          </IconButton>
          {/* notification count */}
          <IconButton
            className="bg-white hover:text-amber-400 hover:shadow-md"
            onClick={notificationClick}
          >
            <Badge
              badgeContent={candidateNotificationBadgeCount}
              className={classes.notificationBadge}
            >
              <NotificationsNoneOutlinedIcon />
            </Badge>
          </IconButton>
          {userData?.loggedUser?.user === "user" ? (
            <>
              <IconButton
                aria-label="Open chat inbox"
                className="bg-white hover:text-green-500 hover:shadow-md"
                onClick={openCompanyChat}
              >
                <ChatOutlinedIcon />
              </IconButton>
            </>
          ) : null}
          {_userData?.role === "Admin" || _userData?.role === "user" ? (
            <>
              <NotificationList
                popoverId={popoverId}
                notifAnchorEl={notifAnchorEl}
                notifOpen={notifOpen}
                notifClose={notifClose}
              />
              <EmailNotificationList
                popoverId={mailPopoverId}
                notifAnchorEl={mailNotifAnchorEl}
                notifOpen={mailNotifOpen}
                notifClose={mailNotifClose}
              />
            </>
          ) : null}
          {_userData?.role === "candidate" ? (
            <>
              <CandidateNotificationList
                popoverId={popoverId}
                notifAnchorEl={notifAnchorEl}
                notifOpen={notifOpen}
                notifClose={notifClose}
              />
              <CandidateEmailList
                popoverId={mailPopoverId}
                notifAnchorEl={mailNotifAnchorEl}
                notifOpen={mailNotifOpen}
                notifClose={mailNotifClose}
              />
            </>
          ) : (
            <>
              <NotificationList
                popoverId={popoverId}
                notifAnchorEl={notifAnchorEl}
                notifOpen={notifOpen}
                notifClose={notifClose}
              />
              <EmailNotificationList
                popoverId={mailPopoverId}
                notifAnchorEl={mailNotifAnchorEl}
                notifOpen={mailNotifOpen}
                notifClose={mailNotifClose}
              />
            </>
          )}

          <div>
            <>
              <Tooltip title="Account settings">
                <div
                  data-testid="acnt-settings-1"
                  onClick={handleClick}
                  className="my-1 flex h-10  cursor-pointer flex-row items-center justify-center rounded-full bg-white px-2 shadow-sm hover:shadow-md"
                >
                  <Avatar
                    id="avatar"
                    src={
                      _userData?.profile_photo
                        ? _userData?.profile_photo
                        : resumeImage
                    }
                    sx={{ width: 30, height: 30, bgcolor: "#007bff" }}
                  >
                    {_userData?.username && _userData?.username.charAt(0)}
                  </Avatar>
                  <p className="cursor-pointer px-1 font-inter text-sm">
                    {_userData?.username}
                  </p>
                </div>
              </Tooltip>
            </>
          </div>
          <Tooltip
            placement="bottom-start"
            title=" Click on the publish button and copy the link or you can share it in any of the social handles."
          >
            {/* <Button
              data-testid="publish-btn-1"
              sx={primaryFilledButton}
              className="text-md font-poppins "
              onClick={handleOpenPublish}
            >
              Publish
            </Button> */}

            {publishStatus ? (
              <Button
                data-testid="publish-btn-1"
                sx={primaryFilledButton}
                style={{
                  width: "130px",
                  height: "2.1em",
                  position: "relative",
                  cursor: "pointer",
                  borderRadius: "6px",
                }}
                startIcon={<PeopleIcon style={{ marginLeft: 10 }} />}
                endIcon={<ArrowDropDownIcon style={{ marginRight: 10 }} />}
                onClick={handleOpenPublish}
              >
                Share
              </Button>
            ) : (
              <Button
                data-testid="publish-btn-1"
                sx={primaryFilledButton}
                style={{
                  width: "130px",
                  height: "2.1em",
                  position: "relative",
                  cursor: "pointer",
                  borderRadius: "6px",
                }}
                startIcon={<PeopleIcon style={{ marginLeft: 10 }} />}
                endIcon={<ArrowDropDownIcon style={{ marginRight: 10 }} />}
                onClick={handleOpenPublish}
              >
                Share
              </Button>
            )}
          </Tooltip>
        </div>
      )}

      {/* publish */}
      <Modal
        open={publish}
        onClose={handleClosePublish}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modal}
      >
        <div className={classes.paper2}>
          <div className={classes.modalHeader}>
            <h5 className={classes.modalTitle} style={{ marginTop: 10 }}>
              Profile Sharing
            </h5>

            <IconButton onClick={handleClosePublish}>
              <CloseIcon style={{ color: "red" }} fontSize="small" />
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {publishStatus ? (
              <>
                <Typography
                  style={{
                    color: "#4CBB17",
                    fontSize: 16,
                    fontFamily: "Kadwa",
                    fontWeight: 600,
                    marginTop: 10,
                    borderStyle: "dotted",
                    padding: 10,
                    borderRadius: 10,
                  }}
                >
                  Your profile is publicly visible via the shared link on
                  Bellohire Spaces
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  style={{
                    color: "#EE4B2B",
                    fontSize: 16,
                    fontFamily: "Kadwa",
                    fontWeight: 600,
                    marginTop: 10,
                    borderStyle: "dotted",
                    padding: 10,
                    borderRadius: 10,
                  }}
                >
                  Your profile is restricted to link sharing only
                </Typography>
              </>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                // color: "#828282",
                fontFamily: "Kadwa",
                fontWeight: 600,
                margin: 15,
                marginTop: 20,
              }}
            >
              Copy the link to share!
            </Typography>

            <div className={classes.sceneLinkContainer}>
              <CopyToClipboard onCopy={onCopyText} text={shareUrl}>
                <div className="copy-area">
                  <IconButton
                    sx={primaryIconButton}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    data-testid="publish-copy-btn"
                  >
                    <FileCopyIcon />
                  </IconButton>

                  <span className={`copy-feedback ${isCopied ? "active" : ""}`}>
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>

              <Tooltip placement="top" title="Open in new window">
                <IconButton
                  // className={classes.copyBtn}
                  sx={primaryIconButton}
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(shareUrl, "_blank");
                  }}
                >
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <form noValidate autoComplete="off">
            <div className={classes.shareContainer}>
              <SocialShare shareUrl={shareUrl} />
            </div>

            {publishStatus ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    fontFamily: "'Poppins', 'Inter', sans-serif",
                    marginLeft: 10,
                  }}
                >
                  Restrict your profile
                </p>

                <p
                  style={{
                    fontSize: 12,
                    marginTop: -10,
                    marginLeft: 10,
                    fontFamily: "'Poppins', 'Inter', sans-serif",
                    color: "#616161",
                  }}
                >
                  Only people with the link can view your profile
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    marginTop: 10,
                  }}
                >
                  <Button
                    data-testid="publish-ur-scene-btn"
                    onClick={handleUnpublishClick}
                    sx={primaryFilledButton}
                  >
                    Restrict Profile
                  </Button>
                </div>
              </div>
            ) : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    fontFamily: "'Poppins', 'Inter', sans-serif",
                    marginLeft: 10,
                  }}
                >
                  Share your profile
                </p>

                <p
                  style={{
                    fontSize: 12,
                    marginTop: -10,
                    marginLeft: 10,
                    fontFamily: "'Poppins', 'Inter', sans-serif",
                    color: "#616161",
                  }}
                >
                  Anyone on the bellohire spaces can view your profile
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    marginTop: 10,
                  }}
                >
                  <Button
                    sx={primaryFilledButton}
                    style={{ marginRight: 5 }}
                    onClick={() => handlePublish()}
                  >
                    Share Profile
                  </Button>
                </div>
              </div>
            )}
          </form>
        </div>
      </Modal>
      {/* </div> */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        className={classes.menuStyle}
        onClose={handleClose}
        PaperProps={{
          marginTop: 10,
          elevation: 0,
          borderRadius: 15,
          sx: {
            marginTop: 10,
            padding: "0px 10px",
            borderRadius: 2,
            overflow: "visible",
            backgroundColor: "#fff",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.22))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              marginTop: 10,
              width: 45,
              height: 35,
              ml: -0.5,
              mr: 1,
              borderRadius: 10,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 6,
              right: 10,
              width: 25,
              height: 12,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              borderRadius: "2px",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem data-testid="profile-btn" onClick={profilePage}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          Profile
        </MenuItem>
        <MenuItem>
          <form
            id="subscriptionForm"
            method="POST"
            action="http://localhost:5000/subscription/upgrade/update/create-customer-portal-session"
          >
            <input type="hidden" name="customerId" id="customerInput" />
          </form>
          <MenuItem onClick={upgradePlan}>
            <ListItemIcon>
              <SubscriptionsIcon />
            </ListItemIcon>
            Upgrade
          </MenuItem>
        </MenuItem>

        <MenuItem
          data-testid="logout-btn"
          onClick={logout}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <ListItemIcon

          // style={{
          //   display: "flex",
          //   flexDirection: "row",
          // }}
          >
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>

      {/* company profile modal */}
      <Modal
        open={companyModalOpen}
        onClose={handleCloseCompanyModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modal}
        style={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "center",

          marginTop: " -10px !important",
          width: "96%",
          flexDirection: "column",
        }}
      >
        <div
          className={classes.paper2}
          style={{ width: "100%", maxWidth: 600 }}
        >
          <div className={classes.modalHeader}>
            <Typography style={{ fontSize: 30, color: "#007BFF" }}>
              Company Details
            </Typography>
            <IconButton onClick={handleCloseCompanyModal}>
              <CloseIcon style={{ color: "red" }} fontSize="small" />
            </IconButton>
          </div>
          <PerfectScrollbar style={{ maxHeight: "80vh" }}>
            <div
              style={{
                minWidth: 150,
                display: "flex",
                flexDirection: "column",
                paddingTop: 10,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 5,
              }}
            >
              <input
                type="file"
                id="upload"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleAttachment}
              />
              <label htmlFor="upload">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <div className="profilepic">
                    <div>
                      <>
                        <Avatar
                          id="avatar"
                          src={profile}
                          style={{
                            objectFit: "cover",
                            width: "100px",
                            borderRadius: 15,
                            height: "100px",
                            transition: "opacity .18s linear",
                          }}
                        />
                      </>
                    </div>
                    <div className="profilepic__content">
                      <span className="profilepic__icon"></span>
                      <span className="profilepic__text">change Profile</span>
                    </div>
                  </div>
                </IconButton>
              </label>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 20,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                label="Company"
                value={companyData?.companyname}
                size="small"
                style={{
                  width: 550,
                  fontSize: 12,
                  marginTop: 20,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Location"
                value={companyData?.location}
                size="small"
                style={{
                  width: 550,
                  fontSize: 12,
                  marginTop: 20,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                label="Phone number"
                value={companyData?.contact}
                size="small"
                style={{
                  width: 550,
                  fontSize: 12,
                  marginTop: 20,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                label="website"
                value={companyData?.website}
                size="small"
                style={{
                  width: 550,
                  fontSize: 12,
                  marginTop: 20,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormControl
                sx={{ m: 1, minWidth: "250 " }}
                size="small"
                style={{
                  minWidth: "550px",
                  marginTop: "20px",
                  marginLeft: "10px",
                }}
              >
                <InputLabel id="demo-simple-select-label">Industry</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  //name="industry"
                  label="Industry"
                  value={industry}
                  onChange={handleIndustry}
                  //style={{ marginLeft: 20 }}
                >
                  {industryList.map((_industry) => (
                    <MenuItem key={_industry} value={_industry}>
                      {_industry}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "25px 27px 10px",
              }}
            >
              <Typography>About Company</Typography>
              <Typography variant="caption" style={{ color: "#007BFF" }}>
                {aboutRemainingCharacters ? aboutRemainingCharacters : "2600"}{" "}
                /2600
              </Typography>
            </div>
            <Editor
              toolbarHidden
              editorStyle={{ height: 200, margin: 25, border: 10 }}
              data-testid="AboutMe-editor"
              editorClassName={classes.editor}
              toolbarClassName={classes.editorToolbar}
              onEditorStateChange={handleAboutChange}
              editorState={editorStateAbout}
              placeholder=" Add description "
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "25px 27px 10px",
              }}
            >
              <Typography>Mission</Typography>
              <Typography variant="caption" style={{ color: "#007BFF" }}>
                {missionRemainingCharacters
                  ? missionRemainingCharacters
                  : "2600"}{" "}
                /2600
              </Typography>
            </div>
            <Editor
              toolbarHidden
              editorStyle={{ height: 200, margin: 25, border: 10 }}
              data-testid="AboutMe-editor"
              editorClassName={classes.editor}
              toolbarClassName={classes.editorToolbar}
              onEditorStateChange={handleMissionChange}
              editorState={editorStateMission}
              placeholder=" Add mission "
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "25px 27px 10px",
              }}
            >
              <Typography>Vision</Typography>
              <Typography variant="caption" style={{ color: "#007BFF" }}>
                {visionRemainingCharacters ? visionRemainingCharacters : "2600"}{" "}
                /2600
              </Typography>
            </div>
            <Editor
              toolbarHidden
              editorStyle={{ height: 200, margin: 25, border: 10 }}
              data-testid="AboutMe-editor"
              editorClassName={classes.editor}
              toolbarClassName={classes.editorToolbar}
              placeholder=" Add vision "
              onEditorStateChange={handleVisionChange}
              editorState={editorStateVision}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <fieldset className={classes.fieldset}>
                <legend>Company Size</legend>
                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className={classes.chipContainer}>
                    <Chip
                      label="1-10"
                      variant={employees === "1-10" ? "default" : "outlined"}
                      color={employees === "1-10" ? "primary" : "default"}
                      onClick={() => handleEmployeeClick("1-10")}
                      // disabled={employees === '1-10'}
                    />
                    <Chip
                      label="11-50"
                      variant={employees === "11-50" ? "default" : "outlined"}
                      color={employees === "11-50" ? "primary" : "default"}
                      onClick={() => handleEmployeeClick("11-50")}
                      // disabled={employees=== '1-10'}
                    />
                    <Chip
                      label="51-200"
                      variant={employees === "51-200" ? "default" : "outlined"}
                      color={employees === "51-200" ? "primary" : "default"}
                      onClick={() => handleEmployeeClick("51-200")}
                    />
                    <Chip
                      label="201-500"
                      variant={employees === "201-500" ? "default" : "outlined"}
                      color={employees === "201-500" ? "primary" : "default"}
                      onClick={() => handleEmployeeClick("201-500")}
                    />
                  </div>
                </div>
              </fieldset>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <fieldset className={classes.fieldset}>
                <legend>Company profile Video</legend>
                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    label="title"
                    size="small"
                    style={{
                      width: "550px ",
                      fontSize: "12px ",
                      marginTop: "20px ",
                    }}
                    value={profileVideoTitle}
                    onChange={(e) => {
                      setProfileVideoTitle(e.target.value);
                    }}
                  />

                  <TextField
                    label="url"
                    size="small"
                    style={{
                      width: "550px ",
                      fontSize: "12px ",
                      marginTop: "20px ",
                    }}
                    value={profileVideoUrl}
                    onChange={(e) => {
                      setProfileVideoUrl(e.target.value);
                    }}
                  />
                </div>
              </fieldset>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <fieldset className={classes.fieldset}>
                <legend>Social Media</legend>
                <div
                  style={{
                    display: "flex",
                    marginTop: 20,
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    style={{
                      //fontWeight: 200,
                      fontFamily: "'Poppins', 'Inter', sans-serif",
                      // marginTop: -15,
                      fontSize: 16,
                    }}
                    variant="body2"
                    gutterBottom
                  >
                    GitHub
                  </Typography>

                  <TextField
                    label="Add a link to you github here"
                    size="small"
                    style={{
                      width: "550px ",
                      fontSize: "12px ",
                      marginBottom: "20px ",
                    }}
                    onChange={(e) => {
                      setGithub(e.target.value);
                    }}
                    value={github}
                  />
                  <Typography
                    style={{
                      //fontWeight: 200,
                      fontFamily: "'Poppins', 'Inter', sans-serif",
                      // marginTop: -15,
                      fontSize: 16,
                    }}
                    variant="body2"
                    gutterBottom
                  >
                    LinkedIn
                  </Typography>
                  <TextField
                    label="Add a link to your linkedIn here"
                    size="small"
                    style={{
                      width: "550px ",
                      fontSize: "12px ",
                      marginBottom: "20px ",
                    }}
                    onChange={(e) => {
                      setLinkedIn(e.target.value);
                    }}
                    value={linkedIn}
                  />
                  <Typography
                    style={{
                      //fontWeight: 200,
                      fontFamily: "'Poppins', 'Inter', sans-serif",
                      // marginTop: -15,
                      fontSize: 16,
                    }}
                    variant="body2"
                    gutterBottom
                  >
                    Facebook
                  </Typography>
                  <TextField
                    label="Add a link to your facebook here"
                    size="small"
                    style={{
                      width: "550px ",
                      fontSize: "12px ",
                      marginBottom: "20px ",
                    }}
                    onChange={(e) => {
                      setFacebook(e.target.value);
                    }}
                    value={facebook}
                  />
                  <Typography
                    style={{
                      //fontWeight: 200,
                      fontFamily: "'Poppins', 'Inter', sans-serif",
                      // marginTop: -15,
                      fontSize: 16,
                    }}
                    variant="body2"
                    gutterBottom
                  >
                    Instagram
                  </Typography>
                  <TextField
                    label="Add a link to your instagram here"
                    size="small"
                    style={{
                      width: "550px ",
                      fontSize: "12px ",
                      marginBottom: "20px ",
                    }}
                    onChange={(e) => {
                      setInstagram(e.target.value);
                    }}
                    value={instagram}
                  />
                  <Typography
                    style={{
                      //fontWeight: 200,
                      fontFamily: "'Poppins', 'Inter', sans-serif",
                      // marginTop: -15,
                      fontSize: 16,
                    }}
                    variant="body2"
                    gutterBottom
                  >
                    Twitter
                  </Typography>
                  <TextField
                    label="Add a link to your twitter here"
                    size="small"
                    style={{
                      width: "550px ",
                      fontSize: "12px ",
                      marginBottom: "20px ",
                    }}
                    onChange={(e) => {
                      setTwitter(e.target.value);
                    }}
                    value={twitter}
                  />
                </div>
              </fieldset>
            </div>
          </PerfectScrollbar>
          <div className={classes.saveButton}>
            <Button
              data-testid="save-certificate"
              size="small"
              sx={primaryFilledButton}
              onClick={handleSaveCompanyData}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
