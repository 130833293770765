import io from "socket.io-client";

const connectionOptions = {
  "force new connection": true,
  reconnection: true,
  reconnectionDelay: 2000,
  reconnectionDelayMax: 60000,
  reconnectionAttempts: "Infinity",
  timeout: 10000,
  transports: ["websocket"],
};

export const initiateSocket = (url, token, room = null) => {
  const hostUrl = `${url}?access_token=${encodeURIComponent(token)}`;
  const socket = io(hostUrl, connectionOptions);

  if (socket) {
    socket.emit("join", { access_token: token, room });
    return socket;
  }
};


export const initiateSupportChatSocket = (url, token, room = null) => {
  const hostUrl = `${url}?access_token=${encodeURIComponent(token)}`;
  const socket = io(hostUrl, connectionOptions);

  if (socket) {
    socket.emit("join_support_chat", { access_token: token, room });
  }

  return socket;
};


export const initiateNotificationSocket = (url, token, room = null) => {
  const hostUrl = `${url}?access_token=${encodeURIComponent(token)}`;
  const socket = io(hostUrl, connectionOptions);

  if (socket) {
    socket.emit("notification_join", { access_token: token, room });
  }

  return socket;
};

export const disconnectSocket = (socket) => {
  if (socket) {
    socket.disconnect();
  }
};

export const subscribeToEvent = (socket, event, cb) => {
  if (!socket) {
    throw new Error("Socket connection missing");
  }
  socket.on(event, (msg) => {
    cb(msg);
  });
};

export const sendEvent = (socket, event, message, token) => {
  if (socket) {
    socket.emit(event, message, token);
  }
};
