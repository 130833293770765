import React, { useEffect } from "react";

interface NotificationProps {
  show: boolean;
  autoHide?: boolean;
  title: string;
  content: React.ReactNode;
  link?: string;
  icon?: string;
  duration?: number; // default to 5 seconds
  onClose: () => void;
}

const NotificationPopup: React.FC<NotificationProps> = ({
  show,
  autoHide = true,
  title,
  content,
  link,
  icon,
  duration = 15000,
  onClose,
}) => {
  useEffect(() => {
    if (show && autoHide) {
      const timer = setTimeout(() => {
        onClose();
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [show, autoHide, duration, onClose]);

  if (!show) return null;

  const handleNavigation = (e: any) => {
    e.stopPropagation();

    if (link) {
      window.open(link, "_blank");
    }
  };

  return (
    <>
      {/* Overlay to prevent clicks */}
      <div
        className="fixed inset-0 z-[100] bg-transparent opacity-50"
        onClick={onClose}
      />
      <div
        className="fixed bottom-4 !cursor-pointer right-4 z-[110] flex items-center rounded-lg bg-white p-4 shadow-lg"
        onClick={handleNavigation} // Prevent click events from propagating to overlay
      >
        {icon && (
          <img
            src={icon}
            alt="icon"
            className="mr-4 h-12 w-12 rounded-full object-cover"
          />
        )}
        <div className="flex flex-col">
          <span className="font-semibold text-gray-800">{title}</span>
          <span className="text-sm text-gray-600">{content}</span>
        </div>
      </div>
    </>
  );
};

export default NotificationPopup;
