import React, { useEffect, useRef, useState } from "react";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import * as THREE from "three";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import {
  TextField,
  Typography,
  InputAdornment,
  Tooltip,
  CircularProgress,
  Avatar,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Button,
} from "@mui/material";
import { useHistory, useLocation } from "react-router";

import broadcastChannel from "utils/BroadcastChannel";
import AvatarViewer from "components/AvatarViewer";

import type { RootState } from "redux/store";
import {
  addRequestScoreActionData,
  addUser,
} from "redux/dashboard/reducers/userSlice";

import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import SendIcon from "@mui/icons-material/Send";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Logout from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import StopRoundedIcon from "@mui/icons-material/StopRounded";

import MinimizeIcon from "@mui/icons-material/Minimize";
import MaximizeIcon from "@mui/icons-material/Maximize";
import FullscreenRoundedIcon from "@mui/icons-material/FullscreenRounded";

import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getResumeData } from "api/dashboard/parseResume";

import {
  updateAssistant,
  updateThreadId,
} from "redux/dashboard/reducers/assistantSlice";

import Modal from "components/Modal";

import { getCompanyDetails } from "api/space/company";
import {
  getBot,
  getBotResponse,
  getDepartmentBot,
  getJobBot,
} from "api/space/bot";

import {
  listMessages,
  getCompanyAssistantList,
  getDepAssistantList,
  getJobAssistantList,
  assistantChat,
} from "api/dashboard/assistant";

import { Helmet } from "react-helmet";
import useRecorder from "hooks/recorder";
import { getSpeechToText } from "api/space/speech-to-text";

import { primaryFilledButton, primaryIconButton } from "theme/elements";
import { DEFAULT_AVATAR, S3_BUCKET_URL } from "constants/production";

import LoginForm from "components/Auth/LoginForm";
import CreateAccountForm from "components/Auth/CreateAccountForm";
import ChatWindow from "components/Chat/ChatWindow";
import { updateChatWindowConfig } from "redux/space/reducers/chatWindowConfigSlice";

import { getSceneByCreated } from "api/scene";
import { useSpaceContext } from "context/spaceContext";

const primaryBtn = {
  color: "#bdbdbd",
  margin: "0px 5px",
  fontFamily: "poppins, sans-serif",
  textTransform: "capitalize",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "#2A8BF2",
    color: "#fff",
    boxShadow:
      "rgba(42,139,242,0.16) 0px 3px 6px, rgba(42,139,242,0.23) 0px 3px 6px",
  },
};

const closeBtn = {
  color: "#ff1744",
  marginRight: "5px",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "#ff1744",
    color: "#fff",
    boxShadow: "rgba(213,0,0,0.16) 0px 3px 6px, rgba(213,0,0,0.23) 0px 3px 6px",
  },
};

const micRecordingButton = {
  color: "#ff1744",
  flexDirection: "row",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "#ff1744",
    color: "#fff",
    boxShadow: "rgba(213,0,0,0.16) 0px 3px 6px, rgba(213,0,0,0.23) 0px 3px 6px",
  },
};

const micButton = {
  color: "#bdbdbd",
  flexDirection: "row",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "#2A8BF2",
    color: "#fff",
    boxShadow:
      "rgba(42,139,242,0.16) 0px 3px 6px, rgba(42,139,242,0.23) 0px 3px 6px",
  },
};

const pathsWithIconButton = [
  "/resume/upload",
  "/department",
  "/department/job",
  "/jobs/recommended",
  "/candidate/login",
  "/job/details",
];

const CompanySpaceLayout = (props) => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const location = useLocation();
  const { pathname } = location;

  const showIconButton = pathsWithIconButton.some((path) =>
    pathname.includes(path),
  );

  const { spaceContextData, setSpaceContextData } = useSpaceContext();

  const [companyDetails, setCompanyDetails] = useState();
  const [sceneData, setSceneData] = React.useState();
  const [jobId, setJobId] = useState();
  const [departmentId, setDepartmentId] = useState();
  const [avatarSpeechData, setAvatarSpeechData] = useState<string>("");
  const [id, setId] = useState();
  const [threadId, setThreadId] = useState();

  const loggedUser = useAppSelector(
    (state: RootState) => state?.userReducer?.user?.loggedUser,
  );

  const userId = useAppSelector(
    (state) => state?.userReducer?.user?.loggedUser?.userKey,
  );
  const userData = useAppSelector((state) => state?.userReducer?.user);

  useEffect(() => {
    if (spaceContextData?.profile) {
      // setCompanyDetails(spaceContextData?.profile.company);
      setSceneData(spaceContextData?.profile.scene);
      setJobId(spaceContextData?.profile?.job?.id);
      setDepartmentId(spaceContextData?.profile?.department?.id);
      // setAvatarSpeechData(spaceContextData?.profile?.scene?.avatar?.speechText);
    }
    return () => { };
  }, [spaceContextData]);

  useEffect(() => {
    if (spaceContextData?.profile.company) {
      setCompanyDetails(spaceContextData?.profile.company);
    }

    return () => { };
  }, [spaceContextData?.profile.company]);

  const renderAvatarSpeech = (speechText: string) => {
    setIsProcessing(false);
    setInputMessage("");
    setMsgPanel(!msgPanel);

    setAvatarSpeechData(speechText);

    setSpaceContextData((prevState) => ({
      ...prevState,
      profile: {
        ...prevState.profile,
        scene: {
          ...prevState.profile.scene,
          avatar: {
            ...prevState.profile.scene.avatar,
            speechText: speechText,
          },
        },
      },
    }));
  };

  const sceneDataRef = useRef();

  const [resumeImage, setResumeImage] = useState();

  useEffect(() => {
    if (loggedUser?.userKey) {
      getResumeData(loggedUser?.userKey)
        .then((response) => {
          setResumeImage(response?.data?.profile_image);
        })
        .catch((error) => {
          console.error("error", error);
        });
    }

    return () => { };
  }, [loggedUser]);

  const actionStatus = useAppSelector(
    (state: RootState) =>
      state?.userReducer?.user?.scoreActionData?.actionStatus,
  );

  const actionUrl = useAppSelector(
    (state: RootState) => state?.userReducer?.user?.scoreActionData?.actionUrl,
  );

  const [bot, setBot] = useState();
  const [msgPanel, setMsgPanel] = useState(false);
  const [inputMessage, setInputMessage] = useState("");

  const [isProcessing, setIsProcessing] = useState(false);

  const [deptBot, setDeptBot] = useState();
  const [jobBot, setJobBot] = useState();

  const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
  const [signUpUrl, setSignUpUrl] = useState<string>("");
  const [loginUrl, setLoginUrl] = useState<string>("");

  const [isChatBtnHovered, setIsChatBtnHovered] = useState(false);

  const [isTechnicalLayout, setIsTechnicalLayout] = useState(false);

  // recorder
  const { recorderState, ...handlers } = useRecorder();
  const { audio, blob } = recorderState;
  const { recordingMinutes, recordingSeconds, initRecording } = recorderState;
  const { startRecording, saveRecording, cancelRecording } = handlers;

  const toggleMicrophone = () => {
    if (initRecording) {
      saveRecording();
    } else {
      startRecording();
    }
  };

  useEffect(() => {
    if (blob) {
      const formData = new FormData();
      formData.append("file", blob, "audio.ogg");

      getSpeechToText(formData)
        .then((res: any) => {
          if (res?.transcription) {
            setIsProcessing(true);
            setInputMessage(res.transcription);
            handleSendClick(res.transcription);
          }
        })
        .catch((err) => {
          console.error("Speech to text error", err);
          renderAvatarSpeech("Sorry I didn't understand. Can you repeat that?");
        });
    }
  }, [blob]);

  const [disableLogout, setDisableLogout] = useState<boolean>(false);

  useEffect(() => {
    const restrictedPaths = [
      "/uploads",
      "/screening/start",
      "/screening",
      "/interview",
      "/technical",
    ];

    const isRestricted = restrictedPaths.some((path) =>
      window.location.href.includes(path),
    );

    setDisableLogout(isRestricted);
  }, [window.location.href]);

  // read company details
  useEffect(() => {
    const fetchSceneData = async () => {
      try {
        if (!companyDetails?._id) return;

        const response = await getSceneByCreated(companyDetails._id);
        const { activeScene, unpublished_data } = response?.data || {};

        if (activeScene || unpublished_data) {
          const sceneData = activeScene || unpublished_data[0];

          setSpaceContextData((prevState) => ({
            ...prevState,
            profile: {
              ...prevState?.profile,
              scene: {
                avatar: {
                  ...sceneData?.avatar,
                  welcomeSpeech: sceneData?.avatar?.speechText,
                },
                camera: sceneData?.camera,
              },
            },
          }));
        }
      } catch (error) {
        console.error("Failed to get scene: ", error);
      }
    };

    fetchSceneData();
  }, [companyDetails?._id]);

  // scene setup data
  useEffect(() => {
    if (companyDetails?._id) {
      if (
        companyDetails?.sceneSetup &&
        Object.keys(companyDetails?.sceneSetup).length
      ) {
        sceneDataRef.current = companyDetails?.sceneSetup;
      } else if (
        companyDetails?.unpublish_data &&
        Object.keys(companyDetails?.unpublish_data).length
      ) {
        sceneDataRef.current = companyDetails?.unpublish_data;
      }
    }

    return () => { };
  }, [companyDetails?._id]);

  useEffect(() => {
    if (companyDetails?._id) {
      getCompanyAssistantList(companyDetails?._id)
        .then((response) => {
          setBot(response?.data[0]);
        })
        .catch((err) => {
          console.log("failed to get bot", err);
        });
    }

    return () => { };
  }, [companyDetails?._id]);

  useEffect(() => {
    if (departmentId) {
      getDepAssistantList(departmentId)
        .then((response) => {
          setDeptBot(response?.data[0]);
        })
        .catch((error) => {
          console.error("Failed to get department bot, Error: ", error);
        });
    }

    return () => { };
  }, [departmentId]);

  useEffect(() => {
    if (jobId) {
      getJobAssistantList(jobId)
        .then((response) => {
          setJobBot(response?.data[0]);
        })
        .catch((error) => {
          console.error("Failed to get job bot, Error: ", error);
        });
    }

    return () => { };
  }, [jobId]);

  useEffect(() => {
    if (initRecording) {
      setMsgPanel(true);
    }

    return () => { };
  }, [initRecording]);

  const fetchThreadId = async () => {
    try {
      const res = await listMessages(id);
      dispatch(updateThreadId({ threadId: res?.data[0]?.thread_id }));
      setThreadId(res?.data[0]?.thread_id);
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    fetchThreadId();
  }, [id]);

  const handleSendClick = (messageText: string) => {
    const query = messageText.trim();
    if (!query) {
      return false;
    }

    setInputMessage("");
    setIsProcessing(true);

    const bots = [jobBot?.id, deptBot?.id, bot?.id].filter(Boolean);

    const results = bots.map((botId) => assistantChat(botId, query, threadId));

    Promise.allSettled(results)
      .then((responses) => {
        const fulfilledResponse = responses.find(
          (response) => response.status === "fulfilled" && response.value,
        );

        if (fulfilledResponse) {
          const speechText =
            fulfilledResponse.value?.messages[0]?.content[0]?.text?.value || "";
          renderAvatarSpeech(speechText);
        } else {
          renderAvatarSpeech("Sorry I'm not able to process your request");
        }
      })
      .catch((error) => {
        console.error("Promise Error: ", error);
        renderAvatarSpeech("Sorry I'm not able to process your request");
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendClick(event.currentTarget.value);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    broadcastChannel.postMessage({});
    dispatch(addUser({}));
    handleClose();
  };

  const handleLoginModalClose = () => {
    setIsLoginModalOpen(!isLoginModalOpen);
  };

  const handleLoginSubmit = ({ userId, userType, twoFactor }) => {
    if (userType !== "candidate") {
      history.push("/company_space/company/start");
      return;
    }

    dispatch(
      addRequestScoreActionData({
        actionStatus: false,
        actionUrl: actionStatus ? actionUrl : "",
      }),
    );

    if (actionStatus) {
      history.push(actionUrl);
    } else if (twoFactor?.status) {
      history.push(`/company_space/login/verify/user/${loggedUser?.userId}`);
    } else {
      setIsLoginModalOpen(false);
    }
  };

  const handleCreateAccountSubmit = () => {
    setIsLoginModalOpen(false);
  };

  const handleUserTokenVerify = (data) => { };

  useEffect(() => {
    if (location?.pathname) {
      setIsTechnicalLayout(location.pathname === "/company_space/technical");

      const currentURL = location.pathname + location.search;
      setSignUpUrl(`${currentURL}#create_account`);
      setLoginUrl(`${currentURL}#login`);
    }
  }, [location]);

  useEffect(() => {
    dispatch(updateChatWindowConfig({ isClosed: true, isMinimized: true }));

    return () => { };
  }, []);

  const handleChatBtnClick = () => {
    if (loggedUser) {
      dispatch(updateChatWindowConfig({ isClosed: false, isMinimized: false }));
    } else {
      setIsLoginModalOpen(true);
    }
  };

  const handleChatBtnMouseEnter = () => {
    setIsChatBtnHovered(true);
  };

  const handleChatBtnMouseLeave = () => {
    setIsChatBtnHovered(false);
  };

  const [isMinimized, setIsMinimized] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleAvatarReady = (status: boolean) => {
    console.log("Avatar ready on company layout, status: ", status)
  }

  return (
    <>
      <ChatWindow
        mode="company"
        sender={userId}
        senderData={userData}
        recipient={spaceContextData?.profile?.company?._id}
        recipientData={spaceContextData?.profile?.company}
        recipientName={
          spaceContextData?.profile?.company?.companyDetails?.companyname
        }
        recipientImage={spaceContextData?.profile?.company?.profileImage}
      />
      <div className="flex h-screen w-screen flex-1 flex-col bg-white dark:bg-black">
        <Helmet>
          <title>{companyDetails?.companyDetails?.companyname}</title>
          <link
            rel="shortcut icon"
            href={
              companyDetails?.profileImage ||
              "/assets/images/logo/apple-icon.png"
            }
          />
        </Helmet>
        {/* Top bar start */}
        <div className="m-2 flex flex-row items-start justify-between rounded-md bg-white p-2 shadow-md lg:items-center dark:bg-gray-800">
          <div className="flex flex-row text-center">
            {showIconButton && (
              <button
                aria-label="back"
                onClick={history.goBack}
                className="mx-1 flex h-10 w-10 items-center justify-center rounded-full border-none bg-transparent text-center text-black shadow-md outline-none transition-all delay-100 ease-in-out hover:bg-blue-500 hover:text-white dark:text-white"
              >
                <ArrowBackIcon />
              </button>
            )}

            <button
              aria-label="home"
              className="mx-1 flex h-10 w-10 items-center justify-center rounded-full border-none bg-transparent text-center text-black shadow-md outline-none transition-all delay-100 ease-in-out hover:bg-blue-500 hover:text-white dark:text-white"
              onClick={() => history.push(`/company_space/company/start`)}
            >
              <HomeOutlinedIcon style={{ color: "inherit" }} />
            </button>

            {loggedUser?.user === "candidate" ||
              loggedUser?.user === "Admin" ||
              loggedUser?.user === "user" ? (
              <Avatar
                alt={loggedUser?.username}
                src={loggedUser?.profile_photo || resumeImage}
                className="mr-4 ml-3 hover:shadow-lg"
                onClick={handleClick}
              />
            ) : (
              <div style={{ marginLeft: 10 }}>
                <Button
                  className="bg-white dark:bg-gray-700 text-primary dark:text-white mr-3 h-10 font-poppins"
                  startIcon={<LoginIcon fontSize="small" />}
                  onClick={() => setIsLoginModalOpen(true)}
                >
                  Login
                </Button>
              </div>
            )}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <div className="flex flex-col justify-center items-center text-enter w-[150px]">
                <Typography
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    fontFamily: "'Poppins', 'Inter', sans-serif !important",
                    textTransform: "uppercase",
                  }}
                >
                  {loggedUser?.username}
                </Typography>
                <Typography
                  style={{
                    fontSize: 14,
                    marginTop: 5,
                    fontFamily: "'Poppins', 'Inter', sans-serif !important",
                  }}
                >
                  {loggedUser?.role}
                </Typography>
                {!disableLogout && (
                  <MenuItem onClick={handleLogout} sx={{ marginTop: 1 }}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        fontFamily: "'Poppins', 'Inter', sans-serif !important",
                      }}
                    >
                      Logout
                    </ListItemText>
                  </MenuItem>
                )}
              </div>
            </Menu>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="flex flex-row justify-center items-center w-auto min-h-11 rounded-3xl mr-5">
              <Avatar
                sx={{ width: 35, height: 35 }}
                style={{ marginLeft: 5, marginRight: 5 }}
                src={companyDetails?.profileImage}
              />
              <div className="flex flex-col mr-4">
                <Typography
                  style={{
                    textAlign: "left",
                    fontSize: 14,
                    fontWeight: "bold",
                    fontFamily: "'Poppins', 'Inter', sans-serif !important",
                    color: "#007bff",
                  }}
                >
                  {" "}
                  {companyDetails?.companyDetails?.companyname}
                </Typography>
              </div>
            </div>

            <button
              aria-label="chat"
              onClick={handleChatBtnClick}
              className="text-md group mx-2 hidden h-10 w-10 transform-none cursor-pointer items-center justify-center rounded-full border-0 bg-white px-2 text-center font-poppins text-blue-500 shadow-md outline-none transition-all delay-100 ease-in hover:w-auto hover:shadow-lg lg:flex dark:bg-gray-700"
            >
              <SendIcon />
              <span className="ml-2 hidden group-hover:inline">
                Message now
              </span>
            </button>
          </div>
        </div>
        {/* Top bar end */}

        <div
          className="flex h-full w-full flex-1 flex-col transition-all delay-100 ease-in-out lg:flex-row"
          id="scene-wrapper"
        >
          {!isClosed && (
            <div
              className={`absolute bottom-3 right-1 z-50 m-2 flex flex-col bg-white dark:bg-gray-800 ${!isMinimized ? "h-60 w-40" : "h-10 w-10"
                } ${!isTechnicalLayout && !isMinimized ? "lg:relative lg:bottom-auto lg:right-auto lg:m-1 lg:h-[calc(100vh-100px)] lg:max-h-[calc(100vh-100px)] lg:min-h-[calc(100vh-100px)] lg:w-[30rem]" : ""}`}
              style={{
                borderRadius: "0.5rem",
              }}
              id="avatar-scene-section"
            >
              <button
                className={`group absolute right-0 top-0 z-50 m-1 flex h-10 w-auto min-w-10 items-center justify-center rounded-full border-none  shadow-md outline-none hover:shadow-lg lg:hidden ${isMinimized ? " bg-white dark:bg-gray-700 " : "bg-white/[0.5] shadow-md blur-md "}`}
                onClick={toggleMinimize}
              >
                {isMinimized ? (
                  <div className="flex items-center">
                    <FullscreenRoundedIcon className="text-gray-600 dark:text-gray-300" />
                    <span className="mx-2 hidden w-28 text-gray-600 group-hover:inline-block dark:text-gray-300">
                      View Avatar
                    </span>
                  </div>
                ) : (
                  <MinimizeIcon className="text-red-500" />
                )}
              </button>

              {sceneData?.avatar && (
                <>
                  <AvatarViewer
                    avatar={sceneData?.avatar}
                    camera={sceneData?.camera}
                    viewMode={true}
                    style={{ borderRadius: "0.5rem" }}
                    onReady={handleAvatarReady}
                    hidden={isMinimized}
                  />
                  {/* close button */}
                  <div className="absolute right-1 top-1 hidden lg:flex">
                    <button
                      className="m-1 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-none bg-white/[0.25] text-red-600 shadow-sm outline-none hover:bg-white hover:shadow-md"
                      onClick={() => setIsClosed(true)}
                    >
                      <CloseRoundedIcon className="text-inherit" />
                    </button>
                  </div>
                  {/* close button end */}
                  {!isMinimized && <MessagePanel />}
                </>
              )}
            </div>
          )}

          <div
            className="mx-2 mt-1 flex h-full w-auto flex-1 rounded-md bg-white p-1 px-4 shadow-md lg:h-[calc(100vh-109px)] lg:max-h-[calc(100vh-109px)] lg:min-h-[calc(100vh-109px)] dark:bg-gray-800"
            id="details-section"
          >
            {props.children}
          </div>
        </div>

        <Modal
          open={isLoginModalOpen}
          handleClose={handleLoginModalClose}
          title=""
          hideBackdrop={false}
        >
          {location?.hash === "#create_account" ? (
            <CreateAccountForm
              loginUrl={loginUrl}
              onSubmit={handleCreateAccountSubmit}
              isShadow={false}
            />
          ) : (
            <LoginForm
              forgotPasswordUrl=""
              signUpUrl={signUpUrl}
              onSubmit={handleLoginSubmit}
              onTokenVerified={handleUserTokenVerify}
            />
          )}
        </Modal>
      </div>
    </>
  );

  function MessagePanel() {
    return (
      <div
        className={`absolute bottom-0 left-0 right-0 flex flex-col-reverse items-center justify-between rounded-md shadow-lg  ${!isTechnicalLayout && "lg:m-4 lg:h-20 lg:w-[27rem] lg:flex-row lg:bg-white lg:p-2 lg:dark:bg-gray-700"}`}
      >
        {bot?.id && (
          <div className="flex flex-row font-poppins lg:pl-5">
            <Tooltip title={msgPanel ? "Close" : "Chat"} arrow>
              <button
                onClick={() => setMsgPanel(!msgPanel)}
                className={`m-1 hidden cursor-pointer text-xs flex-row rounded-full border-0 p-1.5 w-8 h-8 bg-white font-poppins  items-center justify-center text-gray-600 outline-0 transition-all ease-in-out hover:text-blue-500 hover:shadow-md ${!isTechnicalLayout && "lg:flex"}`}
              >
                {msgPanel ? (
                  <CloseRoundedIcon className="text-inherit text-xs" />
                ) : (
                  <ChatBubbleOutlineIcon className="text-inherit text-xs" />
                )}
              </button>
            </Tooltip>

            <div className="flex flex-1 flex-row items-center justify-center">
              <Tooltip title={initRecording ? "Stop Recording" : "Talk"} arrow>
                <button
                  aria-label="mike"
                  onClick={toggleMicrophone}
                  className={`${initRecording ? "bg-red-500 text-white shadow-md shadow-red-600" : "bg-white text-gray-600"} items-center justify-center m-1 w-8 h-8 flex cursor-pointer flex-row rounded-full border-0 p-1.5 font-poppins outline-0 transition-all delay-500 ease-in-out hover:shadow-md`}
                >
                  {initRecording ? (
                    <StopRoundedIcon className="text-inherit text-xs" />
                  ) : (
                    <KeyboardVoiceIcon className="text-inherit text-xs" />
                  )}
                </button>
              </Tooltip>
              <div className="flex flex-row m-0 bg-transparent items-center text-primary p-0 px-3">
                {initRecording ? (
                  <>
                    <p className="text-xs font-poppins m-0">{recordingMinutes}</p>
                    <p className="text-xs font-poppins m-0">:</p>
                    <p className="text-xs font-poppins m-0">{recordingSeconds}</p>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        )}

        {msgPanel ? (
          <div
            id="msg-panel"
            className={`hidden flex-1 flex-row items-center ${!isTechnicalLayout && "lg:flex"}`}
          >
            <input
              defaultValue={inputMessage}
              onBlur={(e) => setInputMessage(e.currentTarget.value)}
              // onChange={(e) => setInputMessage(e.target.value)}
              className="m-1 w-full rounded-md border-0 p-2 text-black outline-0 dark:bg-gray-800 dark:text-white "
              placeholder="Type your message"
              onKeyDown={handleKeyPress}
            />
            <div className="mx-2">
              {isProcessing ? (
                <div className="m-1 flex cursor-pointer text-xs flex-row rounded-full border-0 p-1.5 w-8 h-8 items-center justify-center bg-white font-poppins text-gray-600 outline-0 transition-all ease-in-out group hover:text-blue-500 hover:shadow-md">
                  <CircularProgress size={18} />
                </div>
              ) : (
                <button
                  className="m-1 flex cursor-pointer text-xs flex-row rounded-full border-0 p-1.5 w-8 h-8 items-center justify-center bg-white font-poppins text-gray-600 outline-0 transition-all ease-in-out group hover:text-blue-500 hover:shadow-md"
                  aria-label="send"
                  onClick={() => handleSendClick(inputMessage)}
                >
                  <SendIcon className="group-hover:text-primary text-inherit text-xs" />
                </button>
              )}
            </div>
          </div>
        ) : (
          <PerfectScrollbar
            className={`hidden max-h-16 flex-1 text-wrap break-words pt-1 font-poppins text-black dark:text-white ${!isTechnicalLayout && "lg:flex"}`}
          >
            <div className="flex flex-1 items-center text-wrap break-words font-poppins text-black dark:text-white">
              {avatarSpeechData || spaceContextData?.profile?.scene?.avatar?.speechText}
            </div>
          </PerfectScrollbar>
        )}
      </div>
    );
  }
};

export default CompanySpaceLayout;
